import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/sound/importer/soundImporterSelectors';
import SoundService from 'src/modules/sound/soundService';
import fields from 'src/modules/sound/importer/soundImporterFields';
import { i18n } from 'src/i18n';

const soundImporterActions = importerActions(
  'SOUND_IMPORTER',
  selectors,
  SoundService.import,
  fields,
  i18n('entities.sound.importer.fileName'),
);

export default soundImporterActions;