import authAxios from 'src/modules/shared/axios/authAxios';

export default class UsageLogService {
  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/usage-log`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(
      `/usage-log/${id}`,
    );

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/usage-log`, {
      params,
    });

    return response.data;
  }
}
