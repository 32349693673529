import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import Header from 'src/view/layout/Header';
import Menu from 'src/view/layout/Menu';

function Layout(props) {
  const match = useRouteMatch();

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100 dark:bg-gray-600">
      <Menu url={match.url} />

      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <Header />
        <main
          className="flex-1 overflow-y-auto focus:outline-none"
          tabIndex={0}
        >
          <div className="px-6 xl:px-10 py-6 xl:pt-10 xl:pb-5"  style={{backgroundColor:'rgb(247, 248, 248)'}}>{props.children}</div>
        </main>
      </div>
    </div>
  );
}

export default Layout;
