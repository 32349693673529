import list from 'src/modules/wellnessCheck/list/wellnessCheckListReducers';
import form from 'src/modules/wellnessCheck/form/wellnessCheckFormReducers';
import view from 'src/modules/wellnessCheck/view/wellnessCheckViewReducers';
import destroy from 'src/modules/wellnessCheck/destroy/wellnessCheckDestroyReducers';
import importerReducer from 'src/modules/wellnessCheck/importer/wellnessCheckImporterReducers';
import unarchive from 'src/modules/wellnessCheck/unarchive/wellnessCheckUnarchiveReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  unarchive,
  importer: importerReducer,
});
