import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.affirmation.fields.id'),
  },
  {
    name: 'text',
    label: i18n('entities.affirmation.fields.text'),
  },
  {
    name: 'program',
    label: i18n('entities.affirmation.fields.program'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'orderInList',
    label: i18n('entities.affirmation.fields.orderInList'),
  },
  {
    name: 'textSSML',
    label: i18n('entities.affirmation.fields.textSSML'),
  },
  {
    name: 'detailedDescription',
    label: i18n(
      'entities.affirmation.fields.detailedDescription',
    ),
  },
  {
    name: 'archived',
    label: i18n('entities.affirmation.fields.archived'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'archivedBy',
    label: i18n('entities.affirmation.fields.archivedBy'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'archivedAt',
    label: i18n('entities.affirmation.fields.archivedAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.affirmation.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.affirmation.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
