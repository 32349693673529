import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.subscriptionTier.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.subscriptionTier.fields.title'),
  },
  {
    name: 'summary',
    label: i18n('entities.subscriptionTier.fields.summary'),
  },
  {
    name: 'description',
    label: i18n('entities.subscriptionTier.fields.description'),
  },
  {
    name: 'orderInList',
    label: i18n('entities.subscriptionTier.fields.orderInList'),
  },
  {
    name: 'hidden',
    label: i18n('entities.subscriptionTier.fields.hidden'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'squareArt',
    label: i18n('entities.subscriptionTier.fields.squareArt'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'squareArtHD',
    label: i18n('entities.subscriptionTier.fields.squareArtHD'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'landscapeArt',
    label: i18n('entities.subscriptionTier.fields.landscapeArt'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'landscapeArtHD',
    label: i18n('entities.subscriptionTier.fields.landscapeArtHD'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'portraitArt',
    label: i18n('entities.subscriptionTier.fields.portraitArt'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'portraitArtHD',
    label: i18n('entities.subscriptionTier.fields.portraitArtHD'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'archived',
    label: i18n('entities.subscriptionTier.fields.archived'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'archivedBy',
    label: i18n('entities.subscriptionTier.fields.archivedBy'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'archivedAt',
    label: i18n('entities.subscriptionTier.fields.archivedAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.subscriptionTier.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.subscriptionTier.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
