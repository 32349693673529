import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/wellnessCheck/importer/wellnessCheckImporterSelectors';
import WellnessCheckService from 'src/modules/wellnessCheck/wellnessCheckService';
import fields from 'src/modules/wellnessCheck/importer/wellnessCheckImporterFields';
import { i18n } from 'src/i18n';

const wellnessCheckImporterActions = importerActions(
  'WELLNESS_CHECK_IMPORTER',
  selectors,
  WellnessCheckService.import,
  fields,
  i18n('entities.wellnessCheck.importer.fileName'),
);

export default wellnessCheckImporterActions;
