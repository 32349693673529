import list from 'src/modules/enterpriseAlexaSkill/list/enterpriseAlexaSkillListReducers';
import form from 'src/modules/enterpriseAlexaSkill/form/enterpriseAlexaSkillFormReducers';
import view from 'src/modules/enterpriseAlexaSkill/view/enterpriseAlexaSkillViewReducers';
import destroy from 'src/modules/enterpriseAlexaSkill/destroy/enterpriseAlexaSkillDestroyReducers';
import unarchive from 'src/modules/enterpriseAlexaSkill/unarchive/enterpriseAlexaSkillUnarchiveReducers';
import importerReducer from 'src/modules/enterpriseAlexaSkill/importer/enterpriseAlexaSkillImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  unarchive,
  importer: importerReducer,
});
