import { connectRouter } from 'connected-react-router';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import tenant from 'src/modules/tenant/tenantReducers';
import plan from 'src/modules/plan/planReducers';
import user from 'src/modules/user/userReducers';
import auditLog from 'src/modules/auditLog/auditLogReducers';
import settings from 'src/modules/settings/settingsReducers';
import affirmation from 'src/modules/affirmation/affirmationReducers';
import affirmationProgram from 'src/modules/affirmationProgram/affirmationProgramReducers';
import sound from 'src/modules/sound/soundReducers';
import soundTitle from 'src/modules/soundTitle/soundTitleReducers';
import subscriptionTier from 'src/modules/subscriptionTier/subscriptionTierReducers';
import entitlement from 'src/modules/entitlement/entitlementReducers';
import affirmationProgramEntitlement from 'src/modules/affirmationProgramEntitlement/affirmationProgramEntitlementReducers';
import userEntitlement from 'src/modules/userEntitlement/userEntitlementReducers';
import usageLog from 'src/modules/usageLog/usageLogReducers';
import userAffirmationProgram from 'src/modules/userAffirmationProgram/userAffirmationProgramReducers';
import enterpriseAlexaSkill from 'src/modules/enterpriseAlexaSkill/enterpriseAlexaSkillReducers';
import alexaI18n from 'src/modules/alexaI18n/alexaI18nReducers';
import reporting from 'src/modules/reporting/reportingReducers';
import wellnessCheck from 'src/modules/wellnessCheck/wellnessCheckReducers';
import { combineReducers } from 'redux';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    tenant,
    plan,
    user,
    auditLog,
    settings,
    affirmation,
    affirmationProgram,
    sound,
    soundTitle,
    subscriptionTier,
    entitlement,
    affirmationProgramEntitlement,
    userEntitlement,
    usageLog,
    userAffirmationProgram,
    enterpriseAlexaSkill,
    alexaI18n,
    reporting,
    wellnessCheck,
  });
