import { max } from 'lodash';
import { i18n } from 'src/i18n';
import reportingExporterFields from 'src/modules/reporting/reportingExporterFields';
import reportingSelectors from 'src/modules/reporting/reportingSelectors';
import ReportingService from 'src/modules/reporting/reportingService';
import Errors from 'src/modules/shared/error/errors';
import Exporter from 'src/modules/shared/exporter/exporter';

const prefix = 'REPORTING';

function buildArrayInExcelFriendlyFormat(data) {
  // @ts-ignore
  let length = max([
    data?.platformsBeingUtilized?.length || 0,
    data?.playsPerFrequency?.length || 0,
    data?.programPlays?.length || 0,
  ]);

  let output = [
    {
      ...data,
      platformsBeingUtilized:
        data?.platformsBeingUtilized?.[0],
      playsPerFrequency: data?.playsPerFrequency?.[0],
      programPlays: data?.programPlays?.[0],
    },
  ];

  for (let i = 1; i < length; i++) {
    output.push({
      platformsBeingUtilized:
        data?.platformsBeingUtilized?.[i],
      playsPerFrequency: data?.playsPerFrequency?.[i],
      programPlays: data?.programPlays?.[i],
    });
  }

  return output;
}

const reportingActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  doReset: () => async (dispatch) => {
    dispatch({
      type: reportingActions.RESETED,
    });

    dispatch(reportingActions.doFetch());
  },

  doExport: () => async (dispatch, getState) => {
    try {
      if (
        !reportingExporterFields ||
        !reportingExporterFields.length
      ) {
        throw new Error('exporterFields is required');
      }

      dispatch({
        type: reportingActions.EXPORT_STARTED,
      });

      const filter = reportingSelectors.selectFilter(
        getState(),
      );

      let response = await ReportingService.fetch(filter);
      response = buildArrayInExcelFriendlyFormat(response);

      new Exporter(
        reportingExporterFields,
        i18n('entities.reporting.exporterFileName'),
      ).transformAndExportAsExcelFile(response);

      dispatch({
        type: reportingActions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: reportingActions.EXPORT_ERROR,
      });
    }
  },

  doFetchCurrentFilter:
    () => async (dispatch, getState) => {
      const filter = reportingSelectors.selectFilter(
        getState(),
      );
      const rawFilter = reportingSelectors.selectRawFilter(
        getState(),
      );
      dispatch(reportingActions.doFetch(filter, rawFilter));
    },

  doFetch:
    (filter?, rawFilter?) => async (dispatch, getState) => {
      try {
        dispatch({
          type: reportingActions.FETCH_STARTED,
          payload: { filter, rawFilter },
        });

        const data = await ReportingService.fetch(filter);

        dispatch({
          type: reportingActions.FETCH_SUCCESS,
          payload: {
            data,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: reportingActions.FETCH_ERROR,
        });
      }
    },
};

export default reportingActions;
