import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n(
      'entities.userAffirmationProgram.fields.id',
    ),
  },
  {
    name: 'user',
    label: i18n(
      'entities.userAffirmationProgram.fields.user',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'affirmationProgram',
    label: i18n(
      'entities.userAffirmationProgram.fields.affirmationProgram',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'startDate',
    label: i18n(
      'entities.userAffirmationProgram.fields.startDate',
    ),
  },
  {
    name: 'endDate',
    label: i18n(
      'entities.userAffirmationProgram.fields.endDate',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'sentCount',
    label: i18n(
      'entities.userAffirmationProgram.fields.sentCount',
    ),
  },
  {
    name: 'active',
    label: i18n(
      'entities.userAffirmationProgram.fields.active',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'archived',
    label: i18n(
      'entities.userAffirmationProgram.fields.archived',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'archivedBy',
    label: i18n(
      'entities.userAffirmationProgram.fields.archivedBy',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'archivedAt',
    label: i18n(
      'entities.userAffirmationProgram.fields.archivedAt',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'createdAt',
    label: i18n(
      'entities.userAffirmationProgram.fields.createdAt',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n(
      'entities.userAffirmationProgram.fields.updatedAt',
    ),
    render: exporterRenders.datetime(),
  },
];
