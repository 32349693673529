import listActions from 'src/modules/alexaI18n/list/alexaI18nListActions';
import AlexaI18nService from 'src/modules/alexaI18n/alexaI18nService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'ALEXA_I18N_UNARCHIVE';

const alexaI18nUnarchiveActions = {
  UNARCHIVE_STARTED: `${prefix}_UNARCHIVE_STARTED`,
  UNARCHIVE_SUCCESS: `${prefix}_UNARCHIVE_SUCCESS`,
  UNARCHIVE_ERROR: `${prefix}_UNARCHIVE_ERROR`,

  UNARCHIVE_ALL_STARTED: `${prefix}_UNARCHIVE_ALL_STARTED`,
  UNARCHIVE_ALL_SUCCESS: `${prefix}_UNARCHIVE_ALL_SUCCESS`,
  UNARCHIVE_ALL_ERROR: `${prefix}_UNARCHIVE_ALL_ERROR`,

  doUnarchive: (id) => async (dispatch) => {
    try {
      dispatch({
        type: alexaI18nUnarchiveActions.UNARCHIVE_STARTED,
      });

      await AlexaI18nService.unarchiveAll([id]);

      dispatch({
        type: alexaI18nUnarchiveActions.UNARCHIVE_SUCCESS,
      });

      Message.success(
        i18n('entities.alexaI18n.unarchive.success'),
      );

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/alexa-i18n');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: alexaI18nUnarchiveActions.UNARCHIVE_ERROR,
      });
    }
  },

  doUnarchiveAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type: alexaI18nUnarchiveActions.UNARCHIVE_ALL_STARTED,
      });

      await AlexaI18nService.unarchiveAll(ids);

      dispatch({
        type: alexaI18nUnarchiveActions.UNARCHIVE_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doClearAllSelected());
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(
        i18n('entities.alexaI18n.unarchiveAll.success'),
      );

      getHistory().push('/alexa-i18n');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: alexaI18nUnarchiveActions.UNARCHIVE_ALL_ERROR,
      });
    }
  },
};

export default alexaI18nUnarchiveActions;
