import { capitalize } from 'lodash';
import { i18n } from 'src/i18n';
// import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'accountsActivated',
    label: i18n(
      'entities.reporting.fields.accountsActivated',
    ),
    render: (accountsActivated) => {
      if (!accountsActivated) {
        return '';
      }
      return `${accountsActivated.active} of ${accountsActivated.all}`;
    },
  },
  {
    name: 'activeUsers',
    label: i18n('entities.reporting.fields.activeUsers'),
    render: (activeUsers) => {
      if (!activeUsers) {
        return '';
      }

      return `${activeUsers.active} of ${activeUsers.all}`;
    },
  },
  {
    name: 'disabledUsers',
    label: i18n('entities.reporting.fields.disabledUsers'),
    render: (disabledUsers) => {
      if (!disabledUsers) {
        return '';
      }

      return `${disabledUsers.disabled} of ${disabledUsers.all}`;
    },
  },
  {
    name: 'frequencyMinutesPlayed',
    label: i18n(
      'entities.reporting.fields.frequencyMinutesPlayed',
    ),
    render: (frequencyMinutesPlayed) => {
      if (!frequencyMinutesPlayed) {
        return '';
      }

      return Math.floor(frequencyMinutesPlayed);
    },
  },
  {
    name: 'mindfulIntentionsPushedByApps',
    label: i18n(
      'entities.reporting.fields.mindfulIntentionsPushedByApps',
    ),
  },
  {
    name: 'mindfulIntentionsSentByText',
    label: i18n(
      'entities.reporting.fields.mindfulIntentionsSentByText',
    ),
  },
  {
    name: 'platformsBeingUtilized',
    label: i18n(
      'entities.reporting.fields.platformsBeingUtilized',
    ),
    render: (platform) => {
      if (!platform) {
        return '';
      }

      return platform === 'ios'
        ? 'iOS'
        : capitalize(platform);
    },
  },
  {
    name: 'playsPerFrequency',
    label: i18n(
      'entities.reporting.fields.playsPerFrequency',
    ),
    render: (frequency) => {
      if (!frequency) {
        return '';
      }

      return `${frequency.soundTitle}: ${frequency.count}`;
    },
  },
  {
    name: 'programPlays',
    label: i18n('entities.reporting.fields.programPlays'),
    render: (programPlay) => {
      if (!programPlay) {
        return '';
      }

      return `${programPlay.affirmationProgram}: ${programPlay.count}`;
    },
  },
];
