import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'text',
    label: i18n('entities.affirmation.fields.text'),
    schema: schemas.string(
      i18n('entities.affirmation.fields.text'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'program',
    label: i18n('entities.affirmation.fields.program'),
    schema: schemas.relationToOne(
      i18n('entities.affirmation.fields.program'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'orderInList',
    label: i18n('entities.affirmation.fields.orderInList'),
    schema: schemas.integer(
      i18n('entities.affirmation.fields.orderInList'),
      {},
    ),
  },
  {
    name: 'textSSML',
    label: i18n('entities.affirmation.fields.textSSML'),
    schema: schemas.string(
      i18n('entities.affirmation.fields.textSSML'),
      {},
    ),
  },
  {
    name: 'detailedDescription',
    label: i18n('entities.affirmation.fields.detailedDescription'),
    schema: schemas.string(
      i18n('entities.affirmation.fields.detailedDescription'),
      {},
    ),
  },
];
