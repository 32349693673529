import list from 'src/modules/affirmationProgramEntitlement/list/affirmationProgramEntitlementListReducers';
import form from 'src/modules/affirmationProgramEntitlement/form/affirmationProgramEntitlementFormReducers';
import view from 'src/modules/affirmationProgramEntitlement/view/affirmationProgramEntitlementViewReducers';
import destroy from 'src/modules/affirmationProgramEntitlement/destroy/affirmationProgramEntitlementDestroyReducers';
import importerReducer from 'src/modules/affirmationProgramEntitlement/importer/affirmationProgramEntitlementImporterReducers';
import unarchive from 'src/modules/affirmationProgramEntitlement/unarchive/affirmationProgramEntitlementUnarchiveReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  unarchive,
  importer: importerReducer,
});
