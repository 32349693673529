import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.userEntitlement.fields.id'),
  },
  {
    name: 'user',
    label: i18n('entities.userEntitlement.fields.user'),
    render: (user) => user?.email || String(user),
  },
  {
    name: 'entitlement',
    label: i18n(
      'entities.userEntitlement.fields.entitlement',
    ),
    render: (entitlement) =>
      entitlement?.title || String(entitlement),
  },
  {
    name: 'active',
    label: i18n('entities.userEntitlement.fields.active'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'archived',
    label: i18n('entities.userEntitlement.fields.archived'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'archivedBy',
    label: i18n(
      'entities.userEntitlement.fields.archivedBy',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'archivedAt',
    label: i18n(
      'entities.userEntitlement.fields.archivedAt',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'createdAt',
    label: i18n(
      'entities.userEntitlement.fields.createdAt',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n(
      'entities.userEntitlement.fields.updatedAt',
    ),
    render: exporterRenders.datetime(),
  },
];
