import numeral from 'numeral';

export class AuthCurrencies {
  static symbol(currencyValue) {
    const currency = this.listCurrencies().find(
      (tz) => tz.value === currencyValue,
    );
    if (!currency) {
      return '$';
    }
    return currency.symbol;
  }

  static format(currencyValue, value) {
    return numeral(value).format(
      this.symbol(currencyValue) + '0.00',
    );
  }

  static get values() {
    return this.listCurrencies().map(
      (currency) => currency.value,
    );
  }

  static listCurrencies() {
    return [
      {
        value: 'USD',
        symbol: '$',
      },
      {
        value: 'GBP',
        symbol: '£',
      },
    ];
  }
}
