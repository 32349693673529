import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/affirmation/importer/affirmationImporterSelectors';
import AffirmationService from 'src/modules/affirmation/affirmationService';
import fields from 'src/modules/affirmation/importer/affirmationImporterFields';
import { i18n } from 'src/i18n';

const affirmationImporterActions = importerActions(
  'AFFIRMATION_IMPORTER',
  selectors,
  AffirmationService.import,
  fields,
  i18n('entities.affirmation.importer.fileName'),
);

export default affirmationImporterActions;