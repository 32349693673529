// Place the URL here with the /api suffix.
// Ex.:`https://domain.com/api`;
const backendUrl = `https://api.soaak.dev/api`;

// SwaggerUI Documentation URL
// Leave black if documentation should be hidden
const apiDocumentationUrl = `https://backend-vrww7bnmia-uc.a.run.app/documentation`;

const stripeCustomerUrl = `https://dashboard.stripe.com/customers`;

const homePageLinks = [
  ['Soaak.com', 'https://soaak.com'],
  ['WordPress Login', 'https://soaak.com/wp-admin'],
  ['Member Login', 'https://app.soaak.com'],
  [
    'Dashboards',
    'https://charts.mongodb.com/charts-soaak-ktusn',
  ],
  ['Twilio', 'https://www.twilio.com/console'],
  ['SendGrid', 'https://app.sendgrid.com/'],
  ['Stripe', 'https://dashboard.stripe.com/dashboard'],
  ['Mailchimp', 'https://us2.admin.mailchimp.com/'],
  [
    'Apple Developer Console',
    'https://developer.apple.com/',
  ],
  [
    'Google Play Developer Console',
    'https://play.google.com/console',
  ],
  [
    'Alexa Developer Console',
    'https://developer.amazon.com/alexa/console/ask',
  ],
  ['Roku', 'https://developer.roku.com/'],
];

/**
 * Frontend URL.
 */
const frontendUrl = {
  host: 'ocean.soaak.com',
  protocol: 'https',
};

const frontendAppUrl = 'https://app.soaak.com';

/**
 * Tenant Mode
 * multi: Allow new users to create new tenants.
 * multi-with-subdomain: Same as multi, but enable access to the tenant via subdomain.
 * single: One tenant, the first user to register will be the admin.
 */
const tenantMode = 'single';

/**
 * Plan payments configuration.
 */
const isPlanEnabled = true;
const stripePublishableKey =
  'pk_live_51HYuIJI7OMFn2tF6C9vYrioF2dJmFgxILBjBhpwjJnVUGbUC404uKB9YZNtvNgPt9eS0ExJJ1X0h38dSdZ97pnGB009ny1Y4Lx';

export default {
  frontendUrl,
  backendUrl,
  apiDocumentationUrl,
  tenantMode,
  isPlanEnabled,
  stripePublishableKey,
  stripeCustomerUrl,
  homePageLinks,
  frontendAppUrl,
};
