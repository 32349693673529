import list from 'src/modules/soundTitle/list/soundTitleListReducers';
import form from 'src/modules/soundTitle/form/soundTitleFormReducers';
import view from 'src/modules/soundTitle/view/soundTitleViewReducers';
import destroy from 'src/modules/soundTitle/destroy/soundTitleDestroyReducers';
import importerReducer from 'src/modules/soundTitle/importer/soundTitleImporterReducers';
import unarchive from 'src/modules/soundTitle/unarchive/soundTitleUnarchiveReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  unarchive,
  importer: importerReducer,
});
