import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/userAffirmationProgram/importer/userAffirmationProgramImporterSelectors';
import UserAffirmationProgramService from 'src/modules/userAffirmationProgram/userAffirmationProgramService';
import fields from 'src/modules/userAffirmationProgram/importer/userAffirmationProgramImporterFields';
import { i18n } from 'src/i18n';

const userAffirmationProgramImporterActions = importerActions(
  'USERAFFIRMATIONPROGRAM_IMPORTER',
  selectors,
  UserAffirmationProgramService.import,
  fields,
  i18n('entities.userAffirmationProgram.importer.fileName'),
);

export default userAffirmationProgramImporterActions;