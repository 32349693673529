import listActions from 'src/modules/userEntitlement/list/userEntitlementListActions';
import UserEntitlementService from 'src/modules/userEntitlement/userEntitlementService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'USER_ENTITLEMENT_DEACTIVATE';

const userEntitlementDeactivateActions = {
  DEACTIVATE_STARTED: `${prefix}_DEACTIVATE_STARTED`,
  DEACTIVATE_SUCCESS: `${prefix}_DEACTIVATE_SUCCESS`,
  DEACTIVATE_ERROR: `${prefix}_DEACTIVATE_ERROR`,

  doDeactivate: (id) => async (dispatch) => {
    try {
      dispatch({
        type: userEntitlementDeactivateActions.DEACTIVATE_STARTED,
      });

      await UserEntitlementService.deactivate(id);

      dispatch({
        type: userEntitlementDeactivateActions.DEACTIVATE_SUCCESS,
      });

      Message.success(
        i18n('entities.userEntitlement.deactivate.success'),
      );

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/user-entitlement');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: userEntitlementDeactivateActions.DEACTIVATE_ERROR,
      });
    }
  },
};

export default userEntitlementDeactivateActions;
