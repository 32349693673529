// import moment from 'moment-timezone';
// import { sortBy } from 'lodash';

import moment from 'moment';

export class AuthTimezones {
  static label(value) {
    const timezone = this.listTimezonesForSelect().find(
      (tz) => tz.value === value,
    );
    if (!timezone) {
      return value;
    }
    return timezone.label;
  }

  static defaultTimezone() {
    const ZZ = moment().format('ZZ');

    const realTimezone = ZZ.substr(0, 3);

    const timezone = this.listTimezonesForSelect().find(
      (tz) => tz.realTimezone === realTimezone,
    );
    if (!timezone) {
      return realTimezone;
    }
    return timezone.value;
  }

  static listTimezonesForSelect() {
    return [
      {
        label: 'GMT-12',
        value: 'Etc/GMT+12',
        realTimezone: '-12',
      },
      {
        label: 'GMT-11',
        value: 'Etc/GMT+11',
        realTimezone: '-11',
      },
      {
        label: 'GMT-10',
        value: 'Etc/GMT+10',
        realTimezone: '-10',
      },
      {
        label: 'GMT-9',
        value: 'Etc/GMT+9',
        realTimezone: '-09',
      },
      {
        label: 'GMT-8',
        value: 'Etc/GMT+8',
        realTimezone: '-08',
      },
      {
        label: 'GMT-7',
        value: 'Etc/GMT+7',
        realTimezone: '-07',
      },
      {
        label: 'GMT-6',
        value: 'Etc/GMT+6',
        realTimezone: '-06',
      },
      {
        label: 'GMT-5',
        value: 'Etc/GMT+5',
        realTimezone: '-05',
      },
      {
        label: 'GMT-4',
        value: 'Etc/GMT+4',
        realTimezone: '-04',
      },
      {
        label: 'GMT-3',
        value: 'Etc/GMT+3',
        realTimezone: '-03',
      },
      {
        label: 'GMT-2',
        value: 'Etc/GMT+2',
        realTimezone: '-02',
      },
      {
        label: 'GMT-1',
        value: 'Etc/GMT+1',
        realTimezone: '-01',
      },
      {
        label: 'GMT+0',
        value: 'Etc/GMT+0',
        realTimezone: '+00',
      },
      {
        label: 'GMT+1',
        value: 'Etc/GMT-1',
        realTimezone: '+01',
      },
      {
        label: 'GMT+2',
        value: 'Etc/GMT-2',
        realTimezone: '+02',
      },
      {
        label: 'GMT+3',
        value: 'Etc/GMT-3',
        realTimezone: '+03',
      },
      {
        label: 'GMT+4',
        value: 'Etc/GMT-4',
        realTimezone: '+04',
      },
      {
        label: 'GMT+5',
        value: 'Etc/GMT-5',
        realTimezone: '+05',
      },
      {
        label: 'GMT+6',
        value: 'Etc/GMT-6',
        realTimezone: '+06',
      },
      {
        label: 'GMT+7',
        value: 'Etc/GMT-7',
        realTimezone: '+07',
      },
      {
        label: 'GMT+8',
        value: 'Etc/GMT-8',
        realTimezone: '+08',
      },
      {
        label: 'GMT+9',
        value: 'Etc/GMT-9',
        realTimezone: '+09',
      },
      {
        label: 'GMT+10',
        value: 'Etc/GMT-10',
        realTimezone: '+10',
      },
      {
        label: 'GMT+11',
        value: 'Etc/GMT-11',
        realTimezone: '+11',
      },
      {
        label: 'GMT+12',
        value: 'Etc/GMT-12',
        realTimezone: '+12',
      },
      {
        label: 'GMT+13',
        value: 'Etc/GMT-13',
        realTimezone: '+13',
      },
      {
        label: 'GMT+14',
        value: 'Etc/GMT-14',
        realTimezone: '+14',
      },
    ];
  }
}
