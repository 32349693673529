import SoundService from 'src/modules/sound/soundService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'SOUND_VIEW';

const soundViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: soundViewActions.FIND_STARTED,
      });

      const record = await SoundService.find(id);

      dispatch({
        type: soundViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: soundViewActions.FIND_ERROR,
      });

      getHistory().push('/sound');
    }
  },
};

export default soundViewActions;
