import { i18n } from 'src/i18n';
import affirmationProgramEntitlementEnumerators from 'src/modules/affirmationProgramEntitlement/affirmationProgramEntitlementEnumerators';
import { AuthCurrencies } from 'src/modules/auth/authCurrencies';
import schemas from 'src/modules/shared/yup/yupImporterSchemas';

export default [
  {
    name: 'affirmationProgram',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.affirmationProgram',
    ),
    schema: schemas.relationToOne(
      i18n(
        'entities.affirmationProgramEntitlement.fields.affirmationProgram',
      ),
      {},
    ),
  },
  {
    name: 'source',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.source',
    ),
    schema: schemas.enumerator(
      i18n(
        'entities.affirmationProgramEntitlement.fields.source',
      ),
      {
        options:
          affirmationProgramEntitlementEnumerators.source,
      },
    ),
  },
  {
    name: 'currency',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.currency',
    ),
    schema: schemas.enumerator(
      i18n(
        'entities.affirmationProgramEntitlement.fields.currency',
      ),
      {
        options: AuthCurrencies.values,
      },
    ),
  },
  {
    name: 'storeID',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.storeID',
    ),
    schema: schemas.string(
      i18n(
        'entities.affirmationProgramEntitlement.fields.storeID',
      ),
      {},
    ),
  },
  {
    name: 'storeIDDiscount',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.storeIDDiscount',
    ),
    schema: schemas.string(
      i18n(
        'entities.affirmationProgramEntitlement.fields.storeIDDiscount',
      ),
      {},
    ),
  },
  {
    name: 'storeURL',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.storeURL',
    ),
    schema: schemas.string(
      i18n(
        'entities.affirmationProgramEntitlement.fields.storeURL',
      ),
      {},
    ),
  },
  {
    name: 'retailPrice',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.retailPrice',
    ),
    schema: schemas.decimal(
      i18n(
        'entities.affirmationProgramEntitlement.fields.retailPrice',
      ),
      {},
    ),
  },
  {
    name: 'retailPriceDiscount',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.retailPriceDiscount',
    ),
    schema: schemas.decimal(
      i18n(
        'entities.affirmationProgramEntitlement.fields.retailPriceDiscount',
      ),
      {},
    ),
  },
  {
    name: 'lastSyncDate',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.lastSyncDate',
    ),
    schema: schemas.datetime(
      i18n(
        'entities.affirmationProgramEntitlement.fields.lastSyncDate',
      ),
      {},
    ),
  },
  {
    name: 'syncJSON',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.syncJSON',
    ),
    schema: schemas.string(
      i18n(
        'entities.affirmationProgramEntitlement.fields.syncJSON',
      ),
      {},
    ),
  },
];
