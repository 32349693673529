import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { i18n } from 'src/i18n';
import {
  default as authSelectors,
  default as selectors,
} from 'src/modules/auth/authSelectors';
import PermissionChecker from 'src/modules/auth/permissionChecker';
import layoutActions from 'src/modules/layout/layoutActions';
import actions from 'src/modules/layout/layoutActions';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import menus from 'src/view/menus';

function Menu(props) {
  const dispatch = useDispatch();

  const logoUrl = `/images/logo-color.png`;

  const currentTenant = useSelector(
    authSelectors.selectCurrentTenant,
  );
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );
  const menuVisible = useSelector(
    layoutSelectors.selectMenuVisible,
  );

  const doToggleMenuIfSmall = () => {
    if (window.innerWidth < 768) {
      dispatch(layoutActions.doToggleMenu());
    }
  };

  const doToggleMenu = () => {
    dispatch(layoutActions.doToggleMenu());
  };

  const permissionChecker = new PermissionChecker(
    currentTenant,
    currentUser,
  );

  const selectedKeys = () => {
    const url = props.url;

    const match = menus.find((option) => {
      // @ts-ignore
      if (option.exact) {
        return url === option.path;
      }

      return (
        url === option.path ||
        url.startsWith(option.path + '/')
      );
    });

    if (match) {
      return [match.path];
    }

    return [];
  };

  const match = (permission) => {
    return permissionChecker.match(permission);
  };

  if (!menuVisible) {
    return null;
  }

  return (
    <>
      <div className="lg:hidden">
        <div className="fixed inset-0 flex z-40">
          <div className="fixed inset-0" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
          </div>

          <div
            className={`${menuVisible
              ? `transition ease-in-out duration-300 transform translate-x-0`
              : `transition ease-in-out duration-300 transform -translate-x-full`
              } relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white`}
          >
            <div className="absolute top-0 right-0 -mr-12 pt-2">
              <button
                onClick={doToggleMenu}
                className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              >
                <svg
                  className="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex-shrink-0 flex items-center justify-center px-4">
              <Link onClick={doToggleMenuIfSmall} to="/">
                <img
                  src={logoUrl}
                  className="object-fit max-h-6"
                  alt={i18n('app.title')}
                />
              </Link>
            </div>
            <div className="mt-5 flex-1 h-0 overflow-y-auto">
              <nav className="px-2 space-y-1">
                {menus
                  .filter((menu) =>
                    match(menu.permissionRequired),
                  )
                  .map((menu, index) => (
                    <Link
                      className={`${selectedKeys().includes(menu.path)
                        ? 'bg-lightGreen text-lightPurple group flex items-center px-2 py-2 text-base font-medium rounded-md truncate'
                        : 'text-darkGreen  hover:bg-lightGreen hover:text-lightPurple  group flex items-center px-2 py-2 text-base font-medium rounded-md truncate'
                        }`}
                      onClick={doToggleMenuIfSmall}
                      key={menu.path}
                      to={menu.path}
                    >
                     {menu.imageIcon ? (
                          <img
                            src={menu.imageIcon}
                            className="mr-4 h-5 w-5 object-contain"
                            alt=""
                          />
                        ) : (
                          <FontAwesomeIcon
                            className={`mr-4 h-5 w-5 text-darkGreen`}
                            icon={menu.icon}
                          />
                        )}
                      {menu.label}
                    </Link>
                  ))}
              </nav>
            </div>
          </div>
          <div
            className="flex-shrink-0 w-14"
            aria-hidden="true"
          ></div>
        </div>
      </div>
      <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="flex flex-col w-64">
          <div className="flex flex-grow flex-col overflow-y-auto bg-white pt-5 pb-4">
            <div className="flex flex-shrink-0 items-center justify-center px-4">
              <Link onClick={doToggleMenuIfSmall} to="/">
                <img
                  src={logoUrl}
                  className="object-fit max-h-6"
                  alt={i18n('app.title')}
                />
              </Link>
            </div>
            <div className='bg-white rounded-2xl flex flex-shrink-0 items-center justify-center pr-10 pl-4 gap-3 mx-auto py-3 mt-10 mb-1' style={{ boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.05)', width: 'fit-content' }}>
              <div>
                <img src="/images/user-profile.png" className='rounded-full w-12 h-12' />
              </div>
              <div>
                <p className='text-xs font-medium text-black text-opacity-30'>Welome back</p>
                <h1 className='text-base font-semibold'>{currentUser.fullName}</h1>
              </div>
            </div>
            <div className="mt-5 flex flex-grow flex-col mx-auto px-4">
              <div className="flex-1 space-y-1">
                {menus
                  .filter((menu) =>
                    match(menu.permissionRequired),
                  )
                  .map((menu, index) => (
                    <Link
                      className={`
                        ${selectedKeys().includes(menu.path)
                          ? 'group flex items-center  bg-lightGreen py-2 px-3 text-sm font-medium text-darkGreen justify-start rounded-lg w-11/12 custom-fa-icon'
                          : 'group flex items-center  py-2 px-3 text-sm font-medium text-darkGreen hover:bg-lightGreen justify-start rounded-lg w-11/12 custom-fa-icon'
                        }`}
                      onClick={doToggleMenuIfSmall}
                      key={menu.path}
                      to={menu.path}
                    >
                      {menu.imageIcon ? (
                          <img
                            src={menu.imageIcon}
                            className="mr-4 h-5 w-5 object-contain"
                            alt=""
                          />
                        ) : (
                          <FontAwesomeIcon
                          className={`${selectedKeys().includes(menu.path)
                            ? `text-lightPurple`
                            : ` group-hover:text-lightPurple`
                            } mr-3 w-6 h-6`}
                          icon={menu.icon}
                        />
                        )}
                      {menu.label}
                    </Link>
                  ))}
              </div>

              <div className='block w-full mt-8'>
                <div className=''>
                  <div className="flex space-x-4 py-2 px-3">
                    <a href="https://www.facebook.com/dailysoaak" className="text-darkGreen" target="_blank" rel="noopener noreferrer">
                      <img className="h-6 w-6" src="/images/facebook.png" />
                    </a>
                    <a href="https://www.instagram.com/dailysoaak" className="text-darkGreen" target="_blank" rel="noopener noreferrer">
                      <img className="h-6 w-6" src="/images/instagram.png" />
                    </a>
                    <a href="https://www.linkedin.com/company/soaak" className="text-darkGreen" target="_blank"
                      rel="noopener noreferrer">
                      <img className="h-6 w-6" src="/images/linkedin.png" />
                    </a>
                    <a href="https://www.tiktok.com/@dailysoaak" className="text-darkGreen" target="_blank" rel="noopener noreferrer">
                      <img className="h-6 w-6" src="/images/tiktok.png" />
                    </a>
                  </div>
                </div>
                {/* <button */}
                  {/* // className="group flex w-full focus:outline-transparent focus:outline-0 items-center border-l-4 border-transparent py-2 px-3 font-jakartaMedium text-sm font-medium text-darkGreen hover:bg-gray-50"> */}
                  {/*
    <FontAwesomeIcon className="mr-3 h-6 w-6 text-darkGreen" icon={faSignOutAlt} /> */}
                  {/* <img className="mr-3 h-5 w-5 text-darkGreen" src="/images/sign-out.svg" alt="sign-out" /> */}
                  {/* Sign out */}
                {/* </button> */}
              </div>
            </div>

          </div>

        </div>
      </div>
    </>
  );
}

export default Menu;
