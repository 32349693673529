import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'title',
    label: i18n('entities.subscriptionTier.fields.title'),
    schema: schemas.string(
      i18n('entities.subscriptionTier.fields.title'),
      {
        required: true,
        min: 2,
      },
    ),
  },
  {
    name: 'summary',
    label: i18n('entities.subscriptionTier.fields.summary'),
    schema: schemas.string(
      i18n('entities.subscriptionTier.fields.summary'),
      {},
    ),
  },
  {
    name: 'description',
    label: i18n(
      'entities.subscriptionTier.fields.description',
    ),
    schema: schemas.string(
      i18n('entities.subscriptionTier.fields.description'),
      {},
    ),
  },
  {
    name: 'orderInList',
    label: i18n(
      'entities.subscriptionTier.fields.orderInList',
    ),
    schema: schemas.integer(
      i18n('entities.subscriptionTier.fields.orderInList'),
      {},
    ),
  },
  {
    name: 'hidden',
    label: i18n('entities.subscriptionTier.fields.hidden'),
    schema: schemas.boolean(
      i18n('entities.subscriptionTier.fields.hidden'),
      {},
    ),
  },
  {
    name: 'squareArt',
    label: i18n(
      'entities.subscriptionTier.fields.squareArt',
    ),
    schema: schemas.files(
      i18n('entities.subscriptionTier.fields.squareArt'),
      {
        max: 1,
      },
    ),
  },
  {
    name: 'squareArtHD',
    label: i18n(
      'entities.subscriptionTier.fields.squareArtHD',
    ),
    schema: schemas.files(
      i18n('entities.subscriptionTier.fields.squareArtHD'),
      {
        max: 1,
      },
    ),
  },
  {
    name: 'landscapeArt',
    label: i18n(
      'entities.subscriptionTier.fields.landscapeArt',
    ),
    schema: schemas.files(
      i18n('entities.subscriptionTier.fields.landscapeArt'),
      {
        max: 1,
      },
    ),
  },
  {
    name: 'landscapeArtHD',
    label: i18n(
      'entities.subscriptionTier.fields.landscapeArtHD',
    ),
    schema: schemas.files(
      i18n(
        'entities.subscriptionTier.fields.landscapeArtHD',
      ),
      {
        max: 1,
      },
    ),
  },
  {
    name: 'portraitArt',
    label: i18n(
      'entities.subscriptionTier.fields.portraitArt',
    ),
    schema: schemas.files(
      i18n('entities.subscriptionTier.fields.portraitArt'),
      {
        max: 1,
      },
    ),
  },
  {
    name: 'portraitArtHD',
    label: i18n(
      'entities.subscriptionTier.fields.portraitArtHD',
    ),
    schema: schemas.files(
      i18n(
        'entities.subscriptionTier.fields.portraitArtHD',
      ),
      {
        max: 1,
      },
    ),
  },
];
