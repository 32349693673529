import { i18n } from 'src/i18n';
import { AuthTimezones } from 'src/modules/auth/authTimezones';
import schemas from 'src/modules/shared/yup/yupImporterSchemas';

export default [
  {
    name: 'email',
    label: i18n('user.fields.email'),
    schema: schemas.email(i18n('user.fields.email')),
  },
  {
    name: 'firstName',
    label: i18n('user.fields.firstName'),
    schema: schemas.string(i18n('user.fields.firstName'), {
      max: 80,
    }),
  },
  {
    name: 'lastName',
    label: i18n('user.fields.lastName'),
    schema: schemas.string(i18n('user.fields.lastName'), {
      max: 175,
    }),
  },
  {
    name: 'phoneNumber',
    label: i18n('user.fields.phoneNumber'),
    schema: schemas
      .string(i18n('user.fields.phoneNumber'), {
        matches: /^[0-9]/,
        max: 24,
      })
      .test(
        'is-us',
        i18n('user.validations.phoneNumberUs'),
        (value) => {
          if (!value) {
            return true;
          }

          return value.startsWith('1');
        },
      ),
  },
  {
    name: 'timezone',
    label: i18n('user.fields.timezone'),
    schema: schemas.enumerator(
      i18n('user.fields.timezone'),
      {
        options: AuthTimezones.listTimezonesForSelect().map(
          (timezone) => timezone.value,
        ),
      },
    ),
  },
  {
    name: 'companySlug',
    label: i18n('user.fields.companySlug'),
    schema: schemas.string(i18n('user.fields.companySlug')),
  },
  {
    name: 'testUser',
    label: i18n('user.fields.testUser'),
    schema: schemas.boolean(i18n('user.fields.testUser')),
  },
  {
    name: 'testUserAddMailchimp',
    label: i18n('user.fields.testUserAddMailchimp'),
    schema: schemas.boolean(
      i18n('user.fields.testUserAddMailchimp'),
    ),
  },
];
