import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/entitlement/importer/entitlementImporterSelectors';
import EntitlementService from 'src/modules/entitlement/entitlementService';
import fields from 'src/modules/entitlement/importer/entitlementImporterFields';
import { i18n } from 'src/i18n';

const entitlementImporterActions = importerActions(
  'ENTITLEMENT_IMPORTER',
  selectors,
  EntitlementService.import,
  fields,
  i18n('entities.entitlement.importer.fileName'),
);

export default entitlementImporterActions;