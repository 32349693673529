import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/alexaI18n/importer/alexaI18nImporterSelectors';
import AlexaI18nService from 'src/modules/alexaI18n/alexaI18nService';
import fields from 'src/modules/alexaI18n/importer/alexaI18nImporterFields';
import { i18n } from 'src/i18n';

const alexaI18nImporterActions = importerActions(
  'ALEXAI18N_IMPORTER',
  selectors,
  AlexaI18nService.import,
  fields,
  i18n('entities.alexaI18n.importer.fileName'),
);

export default alexaI18nImporterActions;