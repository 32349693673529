import moment from 'moment';
import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.wellnessCheck.fields.id'),
  },
  {
    name: 'user',
    label: i18n('entities.wellnessCheck.fields.user'),
    render: (user) => user?.email,
  },
  {
    name: 'title',
    label: i18n('entities.wellnessCheck.fields.title'),
  },
  {
    name: 'description',
    label: i18n(
      'entities.wellnessCheck.fields.description',
    ),
  },
  {
    name: 'resultDate',
    label: i18n('entities.wellnessCheck.fields.resultDate'),
    render: (date) =>
      date && moment(date).isValid()
        ? moment(date).format('YYYY-MM-DD')
        : '',
  },
  {
    name: 'resultPdf',
    label: i18n('entities.wellnessCheck.fields.resultPdf'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'resultVideoLink',
    label: i18n(
      'entities.wellnessCheck.fields.resultVideoLink',
    ),
  },
  {
    name: 'intentionText',
    label: i18n(
      'entities.wellnessCheck.fields.intentionText',
    ),
  },
  {
    name: 'intentionSound',
    label: i18n(
      'entities.wellnessCheck.fields.intentionSound',
    ),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'archived',
    label: i18n('entities.wellnessCheck.fields.archived'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'archivedBy',
    label: i18n('entities.wellnessCheck.fields.archivedBy'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'archivedAt',
    label: i18n('entities.wellnessCheck.fields.archivedAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.wellnessCheck.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.wellnessCheck.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
