import {
  faChevronDown,
  faCode,
  faLock,
  faSignOutAlt,
  faThLarge,
  faUser,
  faUserLock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from '@headlessui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import config from 'src/config';
import { i18n } from 'src/i18n';
import authActions from 'src/modules/auth/authActions';
import authSelectors from 'src/modules/auth/authSelectors';
import layoutActions from 'src/modules/layout/layoutActions';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import { getHistory } from 'src/modules/store';
import Avatar from 'src/view/shared/Avatar';
import {Link} from "react-router-dom";

function Header(props) {
  const dispatch = useDispatch();
 

  const doToggleMenu = () => {
    dispatch(layoutActions.doToggleMenu());
  };

  const darkMode = useSelector(
    layoutSelectors.selectDarkMode,
  );

  const userText = useSelector(
    authSelectors.selectCurrentUserNameOrEmailPrefix,
  );
  const userAvatar = useSelector(
    authSelectors.selectCurrentUserAvatar,
  );
  const currentTenant = useSelector(
    authSelectors.selectCurrentTenant,
  );

  const doSignout = () => {
    dispatch(authActions.doSignout());
  };

  const doNavigateToProfile = () => {
    getHistory().push('/profile');
  };

  const doNavigateToPasswordChange = () => {
    getHistory().push('/password-change');
  };

  const doNavigateToPasswordChangeMember = () => {
    getHistory().push('/password-change-member');
  };

  const doNavigateToTenants = () => {
    getHistory().push('/tenant');
  };

  return (
    <nav className="relative z-10 flex-shrink-0 flex h-auto sm:h-20  py-3 bg-white shadow dark:bg-gray-800">
      <button
        onClick={doToggleMenu}
        className="px-4 border-r border-gray-200 dark:border-gray-800 text-lightPurple md:hidden focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
      >
        <svg
          className="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h7"
          />
        </svg>
      </button>

      {process.env.REACT_APP_ENVIRONMENT === 'staging' && (
        <span className="mr-3 inline-flex items-center px-2.5 py-0.5 text-sm font-medium bg-red-100 text-red-800">
          Staging Environment
        </span>
      )}

      <div className="flex-1 px-5 block sm:flex items-center justify-between gap-1">
        <div className=''>
          <p className='mb-1 text-sm font-medium font-syneMedium' style={{color:'#ccd8db'}}>Today’s intentions</p>
          <h6 className='text-base font-SFProDisplayMedium font-medium text-darkGreen'>I have the power to achieve anything</h6>
        </div>
        {/* <div className="mr-6">
          <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
            <input
              type="checkbox"
              name="darkMode"
              id="darkMode"
              checked={darkMode}
              onChange={(event) =>
                dispatch(
                  layoutActions.doDarkModeChange(
                    event.target.checked,
                  ),
                )
              }
              className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            />
            <label
              htmlFor="darkMode"
              className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
            ></label>
          </div>
          <label
            htmlFor="darkMode"
            className="text-xs text-navyBlue dark:text-white"
          >
            <FontAwesomeIcon icon={faMoon} />
          </label>
        </div> */}
        <div className='profile-head flex gap-4'>
          <div className='w-full'>
            {/* <Link to="/21-day-programs/new" className="focus:outline-none flex gap-1 w-full items-center justify-center bg-lightGreen rounded-lg border border-transparent py-2 pr-1 font-medium text-darkGreen hover:text-darkGreen text-sm sm:text-base disabled:cursor-default disabled:opacity-50"> <img src="/images/plus.svg"></img><span>Create new</span></Link> */}
          </div>
          <Menu>
            <Menu.Button className="flex items-center focus:outline-none">
              <Avatar
                size="small"
                src={userAvatar || undefined}
                alt="avatar"
              />

              <div className="text-left mx-2 text-sm font-medium text-lightPurple dark:text-gray-200">
                {userText}
                {['multi', 'multi-with-subdomain'].includes(
                  config.tenantMode,
                ) && (
                    <div className="text-xs font-medium">
                      {currentTenant && currentTenant.name}
                    </div>
                  )}
              </div>

              <FontAwesomeIcon
                className="text-xs ml-2 text-lightPurple"
                icon={faChevronDown}
              />
            </Menu.Button>

            <Menu.Items>
              <div className="absolute right-0 z-20 w-48 py-2 mt-2 bg-white rounded-md shadow-xl dark:bg-gray-800">
                <Menu.Item>
                  <button
                    onClick={doNavigateToProfile}
                    className="w-full text-left block px-4 py-2 text-sm text-lightPurple capitalize transition-colors duration-200 transform dark:text-gray-200 hover:bg-lightPurple hover:text-white dark:hover:text-white"
                    type="button"
                  >
                    <FontAwesomeIcon
                      className="mr-2"
                      icon={faUser}
                    />
                    {i18n('auth.profile.title')}
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={doNavigateToPasswordChange}
                    className="w-full text-left block px-4 py-2 text-sm text-lightPurple capitalize transition-colors duration-200 transform dark:text-gray-200 hover:bg-lightPurple hover:text-white dark:hover:text-white"
                    type="button"
                  >
                    <FontAwesomeIcon
                      className="mr-2"
                      icon={faLock}
                    />
                    {i18n('auth.passwordChange.title')}
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={doNavigateToPasswordChangeMember}
                    className="w-full text-left block px-4 py-2 text-sm text-lightPurple capitalize transition-colors duration-200 transform dark:text-gray-200 hover:bg-lightPurple hover:text-white dark:hover:text-white"
                    type="button"
                  >
                    <FontAwesomeIcon
                      className="mr-2"
                      icon={faUserLock}
                    />
                    {i18n('auth.passwordChangeMember.title')}
                  </button>
                </Menu.Item>
                {['multi', 'multi-with-subdomain'].includes(
                  config.tenantMode,
                ) && (
                    <Menu.Item>
                      <button
                        onClick={doNavigateToTenants}
                        className="w-full text-left block px-4 py-2 text-sm text-lightPurple capitalize transition-colors duration-200 transform dark:text-gray-200 hover:bg-lightPurple hover:text-white dark:hover:text-white"
                        type="button"
                      >
                        <FontAwesomeIcon
                          className="mr-2"
                          icon={faThLarge}
                        />
                        {i18n('auth.tenants')}
                      </button>
                    </Menu.Item>
                  )}
                {config.apiDocumentationUrl && (
                  <Menu.Item>
                    <a
                      href={config.apiDocumentationUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="w-full text-left block px-4 py-2 text-sm text-lightPurple capitalize transition-colors duration-200 transform dark:text-gray-200 hover:bg-lightPurple hover:text-white dark:hover:text-white"
                    >
                      <FontAwesomeIcon
                        className="mr-2"
                        icon={faCode}
                      />
                      {i18n('api.menu')}
                    </a>
                  </Menu.Item>
                )}
                <Menu.Item>
                  <button
                    onClick={doSignout}
                    className="w-full text-left block px-4 py-2 text-sm text-lightPurple capitalize transition-colors duration-200 transform dark:text-gray-200 hover:bg-lightPurple hover:text-white dark:hover:text-white"
                    type="button"
                  >
                    <FontAwesomeIcon
                      className="mr-2"
                      icon={faSignOutAlt}
                    />
                    {i18n('auth.signout')}
                  </button>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Menu>
        </div>
      </div>
    </nav>
  );
}

export default Header;
