import listActions from 'src/modules/enterpriseAlexaSkill/list/enterpriseAlexaSkillListActions';
import EnterpriseAlexaSkillService from 'src/modules/enterpriseAlexaSkill/enterpriseAlexaSkillService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'ENTERPRISE_ALEXA_SKILL_UNARCHIVE';

const enterpriseAlexaSkillUnarchiveActions = {
  UNARCHIVE_STARTED: `${prefix}_UNARCHIVE_STARTED`,
  UNARCHIVE_SUCCESS: `${prefix}_UNARCHIVE_SUCCESS`,
  UNARCHIVE_ERROR: `${prefix}_UNARCHIVE_ERROR`,

  UNARCHIVE_ALL_STARTED: `${prefix}_UNARCHIVE_ALL_STARTED`,
  UNARCHIVE_ALL_SUCCESS: `${prefix}_UNARCHIVE_ALL_SUCCESS`,
  UNARCHIVE_ALL_ERROR: `${prefix}_UNARCHIVE_ALL_ERROR`,

  doUnarchive: (id) => async (dispatch) => {
    try {
      dispatch({
        type: enterpriseAlexaSkillUnarchiveActions.UNARCHIVE_STARTED,
      });

      await EnterpriseAlexaSkillService.unarchiveAll([id]);

      dispatch({
        type: enterpriseAlexaSkillUnarchiveActions.UNARCHIVE_SUCCESS,
      });

      Message.success(
        i18n(
          'entities.enterpriseAlexaSkill.unarchive.success',
        ),
      );

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/enterprise-alexa-skill');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: enterpriseAlexaSkillUnarchiveActions.UNARCHIVE_ERROR,
      });
    }
  },

  doUnarchiveAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type: enterpriseAlexaSkillUnarchiveActions.UNARCHIVE_ALL_STARTED,
      });

      await EnterpriseAlexaSkillService.unarchiveAll(ids);

      dispatch({
        type: enterpriseAlexaSkillUnarchiveActions.UNARCHIVE_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doClearAllSelected());
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(
        i18n(
          'entities.enterpriseAlexaSkill.unarchiveAll.success',
        ),
      );

      getHistory().push('/enterprise-alexa-skill');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: enterpriseAlexaSkillUnarchiveActions.UNARCHIVE_ALL_ERROR,
      });
    }
  },
};

export default enterpriseAlexaSkillUnarchiveActions;
