import { createSelector } from 'reselect';

const selectRaw = (state) => state.reporting;

const selectLoading = createSelector(
  [selectRaw],
  (raw) => raw.loading,
);

const selectExportLoading = createSelector(
  [selectRaw],
  (raw) => raw.exportLoading,
);

const selectData = createSelector(
  [selectRaw],
  (raw) => raw.data,
);

const selectFilter = createSelector([selectRaw], (raw) => {
  return raw.filter;
});

const selectRawFilter = createSelector(
  [selectRaw],
  (raw) => {
    return raw.rawFilter;
  },
);

const reportingSelectors = {
  selectData,
  selectLoading,
  selectFilter,
  selectExportLoading,
  selectRawFilter,
};

export default reportingSelectors;
