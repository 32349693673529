import list from 'src/modules/alexaI18n/list/alexaI18nListReducers';
import form from 'src/modules/alexaI18n/form/alexaI18nFormReducers';
import view from 'src/modules/alexaI18n/view/alexaI18nViewReducers';
import destroy from 'src/modules/alexaI18n/destroy/alexaI18nDestroyReducers';
import unarchive from 'src/modules/alexaI18n/unarchive/alexaI18nUnarchiveReducers';
import importerReducer from 'src/modules/alexaI18n/importer/alexaI18nImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  unarchive,
  importer: importerReducer,
});
