import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'user',
    label: i18n('entities.userEntitlement.fields.user'),
    schema: schemas.relationToOne(
      i18n('entities.userEntitlement.fields.user'),
      {},
    ),
  },
  {
    name: 'entitlement',
    label: i18n(
      'entities.userEntitlement.fields.entitlement',
    ),
    schema: schemas.relationToOne(
      i18n('entities.userEntitlement.fields.entitlement'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'active',
    label: i18n('entities.userEntitlement.fields.active'),
    schema: schemas.boolean(
      i18n('entities.userEntitlement.fields.active'),
      {},
    ),
  },
];
