import listActions from 'src/modules/userAffirmationProgram/list/userAffirmationProgramListActions';
import UserAffirmationProgramService from 'src/modules/userAffirmationProgram/userAffirmationProgramService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'USER_AFFIRMATION_PROGRAM_UNARCHIVE';

const userAffirmationProgramUnarchiveActions = {
  UNARCHIVE_STARTED: `${prefix}_UNARCHIVE_STARTED`,
  UNARCHIVE_SUCCESS: `${prefix}_UNARCHIVE_SUCCESS`,
  UNARCHIVE_ERROR: `${prefix}_UNARCHIVE_ERROR`,

  UNARCHIVE_ALL_STARTED: `${prefix}_UNARCHIVE_ALL_STARTED`,
  UNARCHIVE_ALL_SUCCESS: `${prefix}_UNARCHIVE_ALL_SUCCESS`,
  UNARCHIVE_ALL_ERROR: `${prefix}_UNARCHIVE_ALL_ERROR`,

  doUnarchive: (id) => async (dispatch) => {
    try {
      dispatch({
        type:
          userAffirmationProgramUnarchiveActions.UNARCHIVE_STARTED,
      });

      await UserAffirmationProgramService.unarchiveAll([
        id,
      ]);

      dispatch({
        type:
          userAffirmationProgramUnarchiveActions.UNARCHIVE_SUCCESS,
      });

      Message.success(
        i18n(
          'entities.userAffirmationProgram.unarchive.success',
        ),
      );

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/user-affirmation-program');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type:
          userAffirmationProgramUnarchiveActions.UNARCHIVE_ERROR,
      });
    }
  },

  doUnarchiveAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type:
          userAffirmationProgramUnarchiveActions.UNARCHIVE_ALL_STARTED,
      });

      await UserAffirmationProgramService.unarchiveAll(ids);

      dispatch({
        type:
          userAffirmationProgramUnarchiveActions.UNARCHIVE_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doClearAllSelected());
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(
        i18n(
          'entities.userAffirmationProgram.unarchiveAll.success',
        ),
      );

      getHistory().push('/user-affirmation-program');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type:
          userAffirmationProgramUnarchiveActions.UNARCHIVE_ALL_ERROR,
      });
    }
  },
};

export default userAffirmationProgramUnarchiveActions;
