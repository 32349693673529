import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.usageLog.fields.id'),
  },
  {
    name: 'device',
    label: i18n('entities.usageLog.fields.device'),
  },
  {
    name: 'payload',
    label: i18n('entities.usageLog.fields.payload'),
  },
  {
    name: 'localTimestamp',
    label: i18n('entities.usageLog.fields.localTimestamp'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'affirmationPushVia',
    label: i18n('entities.usageLog.fields.affirmationPushVia'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.usageLog.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.usageLog.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
