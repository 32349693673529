import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.sound.fields.id'),
  },
  {
    name: 'name',
    label: i18n('entities.sound.fields.name'),
  },
  {
    name: 'soundTitle',
    label: i18n('entities.sound.fields.soundTitle'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'type',
    label: i18n('entities.sound.fields.type'),
  },
  // {
  //   name: 'summary',
  //   label: i18n('entities.sound.fields.summary'),
  // },
  // {
  //   name: 'summarySSML',
  //   label: i18n('entities.sound.fields.summarySSML'),
  // },
  // {
  //   name: 'description',
  //   label: i18n('entities.sound.fields.description'),
  // },
  // {
  //   name: 'descriptionSSML',
  //   label: i18n('entities.sound.fields.descriptionSSML'),
  // },
  {
    name: 'hidden',
    label: i18n('entities.sound.fields.hidden'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'audioURL',
    label: i18n('entities.sound.fields.audioURL'),
    render: exporterRenders.filesOrImages(),
  },
  // {
  //   name: 'alexaAudioURL',
  //   label: i18n('entities.sound.fields.alexaAudioURL'),
  //   render: exporterRenders.filesOrImages(),
  // },
  // {
  //   name: 'squareArt',
  //   label: i18n('entities.sound.fields.squareArt'),
  //   render: exporterRenders.filesOrImages(),
  // },
  // {
  //   name: 'squareArtHD',
  //   label: i18n('entities.sound.fields.squareArtHD'),
  //   render: exporterRenders.filesOrImages(),
  // },
  // {
  //   name: 'landscapeArt',
  //   label: i18n('entities.sound.fields.landscapeArt'),
  //   render: exporterRenders.filesOrImages(),
  // },
  // {
  //   name: 'landscapeArtHD',
  //   label: i18n('entities.sound.fields.landscapeArtHD'),
  //   render: exporterRenders.filesOrImages(),
  // },
  // {
  //   name: 'portraitArt',
  //   label: i18n('entities.sound.fields.portraitArt'),
  //   render: exporterRenders.filesOrImages(),
  // },
  // {
  //   name: 'portraitArtHD',
  //   label: i18n('entities.sound.fields.portraitArtHD'),
  //   render: exporterRenders.filesOrImages(),
  // },
  {
    name: 'archived',
    label: i18n('entities.sound.fields.archived'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'archivedBy',
    label: i18n('entities.sound.fields.archivedBy'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'archivedAt',
    label: i18n('entities.sound.fields.archivedAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.sound.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.sound.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
