import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('user.fields.id'),
  },
  {
    name: 'email',
    label: i18n('user.fields.email'),
  },
  {
    name: 'fullName',
    label: i18n('user.fields.fullName'),
  },
  {
    name: 'phoneNumber',
    label: i18n('user.fields.phoneNumber'),
  },
  {
    name: 'pushOptIn',
    label: i18n('user.fields.pushOptIn'),
  },
  {
    name: 'phoneOptIn',
    label: i18n('user.fields.phoneOptIn'),
  },
  {
    name: 'lastUsedDevice',
    label: i18n('user.fields.lastUsedDevice'),
  },
  {
    name: 'timezone',
    label: i18n('user.fields.timezone'),
  },
  {
    name: 'currency',
    label: i18n('user.fields.currency'),
  },
  {
    name: 'roles',
    label: i18n('user.fields.roles'),
    render: exporterRenders.stringArray(),
  },
  {
    name: 'status',
    label: i18n('user.fields.status'),
  },
  {
    name: 'testUser',
    label: i18n('user.fields.testUser'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'testUserAddMailchimp',
    label: i18n('user.fields.testUserAddMailchimp'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'createdAt',
    label: i18n('user.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
];
