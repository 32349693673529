/**
 * Storage permissions.
 *
 * @id - Used to identify the rule on permissions and upload.
 * @folder - Folder where the files will be saved
 * @maxSizeInBytes - Max allowed size in bytes
 * @bypassWritingPermissions - Does not validate if the user has permission to write
 * @publicRead - The file can be publicly accessed via the URL without the need for a signed token
 */
export default class Storage {
  static get values() {
    return {
      affirmationProgramSoundsAudioURL: {
        id: 'affirmationProgramSoundsAudioURL',
        folder:
          'tenant/:tenantId/affirmationProgram/soundsAudioURL',
        maxSizeInBytes: 500 * 1024 * 1024,
      },
      affirmationProgramSquareArt: {
        id: 'affirmationProgramSquareArt',
        folder:
          'tenant/:tenantId/affirmationProgram/squareArt',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },
      affirmationProgramSquareArtHD: {
        id: 'affirmationProgramSquareArtHD',
        folder:
          'tenant/:tenantId/affirmationProgram/squareArtHD',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },
      affirmationProgramLandscapeArt: {
        id: 'affirmationProgramLandscapeArt',
        folder:
          'tenant/:tenantId/affirmationProgram/landscapeArt',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },
      affirmationProgramLandscapeArtHD: {
        id: 'affirmationProgramLandscapeArtHD',
        folder:
          'tenant/:tenantId/affirmationProgram/landscapeArtHD',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },
      affirmationProgramPortraitArt: {
        id: 'affirmationProgramPortraitArt',
        folder:
          'tenant/:tenantId/affirmationProgram/portraitArt',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },
      affirmationProgramPortraitArtHD: {
        id: 'affirmationProgramPortraitArtHD',
        folder:
          'tenant/:tenantId/affirmationProgram/portraitArtHD',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },

      affirmationCommentaryAudio: {
        id: 'affirmationCommentaryAudio',
        folder:
          'tenant/:tenantId/affirmation/commentaryAudio',
        maxSizeInBytes: 500 * 1024 * 1024,
      },

      soundAudioURL: {
        id: 'soundAudioURL',
        folder: 'tenant/:tenantId/sound/audioURL',
        maxSizeInBytes: 500 * 1024 * 1024,
      },
      soundAlexaAudioURL: {
        id: 'soundAlexaAudioURL',
        folder: 'tenant/:tenantId/sound/alexaAudioURL',
        maxSizeInBytes: 500 * 1024 * 1024,
      },
      soundSquareArt: {
        id: 'soundSquareArt',
        folder: 'tenant/:tenantId/sound/squareArt',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },
      soundSquareArtHD: {
        id: 'soundSquareArtHD',
        folder: 'tenant/:tenantId/sound/squareArtHD',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },
      soundLandscapeArt: {
        id: 'soundLandscapeArt',
        folder: 'tenant/:tenantId/sound/landscapeArt',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },
      soundLandscapeArtHD: {
        id: 'soundLandscapeArtHD',
        folder: 'tenant/:tenantId/sound/landscapeArtHD',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },
      soundPortraitArt: {
        id: 'soundPortraitArt',
        folder: 'tenant/:tenantId/sound/portraitArt',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },
      soundPortraitArtHD: {
        id: 'soundPortraitArtHD',
        folder: 'tenant/:tenantId/sound/portraitArtHD',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },

      soundTitleSquareArt: {
        id: 'soundTitleSquareArt',
        folder: 'tenant/:tenantId/soundTitle/squareArt',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },
      soundTitleSquareArtHD: {
        id: 'soundTitleSquareArtHD',
        folder: 'tenant/:tenantId/soundTitle/squareArtHD',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },
      soundTitleLandscapeArt: {
        id: 'soundTitleLandscapeArt',
        folder: 'tenant/:tenantId/soundTitle/landscapeArt',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },
      soundTitleLandscapeArtHD: {
        id: 'soundTitleLandscapeArtHD',
        folder:
          'tenant/:tenantId/soundTitle/landscapeArtHD',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },
      soundTitlePortraitArt: {
        id: 'soundTitlePortraitArt',
        folder: 'tenant/:tenantId/soundTitle/portraitArt',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },
      soundTitlePortraitArtHD: {
        id: 'soundTitlePortraitArtHD',
        folder: 'tenant/:tenantId/soundTitle/portraitArtHD',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },

      subscriptionTierSquareArt: {
        id: 'subscriptionTierSquareArt',
        folder:
          'tenant/:tenantId/subscriptionTier/squareArt',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },
      subscriptionTierSquareArtHD: {
        id: 'subscriptionTierSquareArtHD',
        folder:
          'tenant/:tenantId/subscriptionTier/squareArtHD',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },
      subscriptionTierLandscapeArt: {
        id: 'subscriptionTierLandscapeArt',
        folder:
          'tenant/:tenantId/subscriptionTier/landscapeArt',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },
      subscriptionTierLandscapeArtHD: {
        id: 'subscriptionTierLandscapeArtHD',
        folder:
          'tenant/:tenantId/subscriptionTier/landscapeArtHD',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },
      subscriptionTierPortraitArt: {
        id: 'subscriptionTierPortraitArt',
        folder:
          'tenant/:tenantId/subscriptionTier/portraitArt',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },
      subscriptionTierPortraitArtHD: {
        id: 'subscriptionTierPortraitArtHD',
        folder:
          'tenant/:tenantId/subscriptionTier/portraitArtHD',
        maxSizeInBytes: 500 * 1024 * 1024,
        publicRead: true,
      },

      settingsLogos: {
        id: 'settingsLogos',
        folder: 'tenant/:tenantId/settings/logos',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      settingsBackgroundImages: {
        id: 'settingsBackgroundImages',
        folder:
          'tenant/:tenantId/settings/backgroundImages',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      userAvatarsProfiles: {
        id: 'userAvatarsProfiles',
        folder: 'user/avatars/profile/:userId',
        maxSizeInBytes: 10 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },

      wellnessCheckIntentionSound: {
        id: 'wellnessCheckIntentionSound',
        folder:
          'tenant/:tenantId/wellnessCheck/intentionSound',
        maxSizeInBytes: 500 * 1024 * 1024,
      },

      wellnessCheckResultPdf: {
        id: 'wellnessCheckResultPdf',
        folder: 'tenant/:tenantId/wellnessCheck/resultPdf',
        maxSizeInBytes: 500 * 1024 * 1024,
      },
    };
  }
}
