import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.soundTitle.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.soundTitle.fields.title'),
  },
  {
    name: 'slug',
    label: i18n('entities.soundTitle.fields.slug'),
  },
  // {
  //   name: 'summary',
  //   label: i18n('entities.soundTitle.fields.summary'),
  // },
  // {
  //   name: 'summarySSML',
  //   label: i18n('entities.soundTitle.fields.summarySSML'),
  // },
  {
    name: 'description',
    label: i18n('entities.soundTitle.fields.description'),
  },
  {
    name: 'descriptionSSML',
    label: i18n(
      'entities.soundTitle.fields.descriptionSSML',
    ),
  },
  {
    name: 'orderInList',
    label: i18n('entities.soundTitle.fields.orderInList'),
  },
  {
    name: 'wellnessCheck',
    label: i18n('entities.soundTitle.fields.wellnessCheck'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'includedInTier',
    label: i18n(
      'entities.soundTitle.fields.includedInTier',
    ),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'hidden',
    label: i18n('entities.soundTitle.fields.hidden'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'squareArt',
    label: i18n('entities.soundTitle.fields.squareArt'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'squareArtHD',
    label: i18n('entities.soundTitle.fields.squareArtHD'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'landscapeArt',
    label: i18n('entities.soundTitle.fields.landscapeArt'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'landscapeArtHD',
    label: i18n(
      'entities.soundTitle.fields.landscapeArtHD',
    ),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'portraitArt',
    label: i18n('entities.soundTitle.fields.portraitArt'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'portraitArtHD',
    label: i18n('entities.soundTitle.fields.portraitArtHD'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'archived',
    label: i18n('entities.soundTitle.fields.archived'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'archivedBy',
    label: i18n('entities.soundTitle.fields.archivedBy'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'archivedAt',
    label: i18n('entities.soundTitle.fields.archivedAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.soundTitle.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.soundTitle.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
