import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'user',
    label: i18n(
      'entities.userAffirmationProgram.fields.user',
    ),
    schema: schemas.relationToOne(
      i18n('entities.userAffirmationProgram.fields.user'),
      {},
    ),
  },
  {
    name: 'affirmationProgram',
    label: i18n(
      'entities.userAffirmationProgram.fields.affirmationProgram',
    ),
    schema: schemas.relationToOne(
      i18n(
        'entities.userAffirmationProgram.fields.affirmationProgram',
      ),
      {},
    ),
  },
  {
    name: 'startDate',
    label: i18n(
      'entities.userAffirmationProgram.fields.startDate',
    ),
    schema: schemas.date(
      i18n(
        'entities.userAffirmationProgram.fields.startDate',
      ),
      {},
    ),
  },
  {
    name: 'sentCount',
    label: i18n(
      'entities.userAffirmationProgram.fields.sentCount',
    ),
    schema: schemas.integer(
      i18n(
        'entities.userAffirmationProgram.fields.sentCount',
      ),
      {},
    ),
  },
  {
    name: 'active',
    label: i18n(
      'entities.userAffirmationProgram.fields.active',
    ),
    schema: schemas.boolean(
      i18n('entities.userAffirmationProgram.fields.active'),
      {},
    ),
  },
];
