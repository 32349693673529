import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'partner',
    label: i18n('entities.enterpriseAlexaSkill.fields.partner'),
    schema: schemas.string(
      i18n('entities.enterpriseAlexaSkill.fields.partner'),
      {
        "max": 255,
        "required": true
      },
    ),
  },
  {
    name: 'installation',
    label: i18n('entities.enterpriseAlexaSkill.fields.installation'),
    schema: schemas.string(
      i18n('entities.enterpriseAlexaSkill.fields.installation'),
      {
        "max": 255
      },
    ),
  },
  {
    name: 'skillId',
    label: i18n('entities.enterpriseAlexaSkill.fields.skillId'),
    schema: schemas.string(
      i18n('entities.enterpriseAlexaSkill.fields.skillId'),
      {
        "max": 255,
        "required": true
      },
    ),
  },
  {
    name: 'soundTitles',
    label: i18n('entities.enterpriseAlexaSkill.fields.soundTitles'),
    schema: schemas.relationToMany(
      i18n('entities.enterpriseAlexaSkill.fields.soundTitles'),
      {},
    ),
  },
  {
    name: 'live',
    label: i18n('entities.enterpriseAlexaSkill.fields.live'),
    schema: schemas.boolean(
      i18n('entities.enterpriseAlexaSkill.fields.live'),
      {},
    ),
  },
];