import list from 'src/modules/userAffirmationProgram/list/userAffirmationProgramListReducers';
import form from 'src/modules/userAffirmationProgram/form/userAffirmationProgramFormReducers';
import view from 'src/modules/userAffirmationProgram/view/userAffirmationProgramViewReducers';
import destroy from 'src/modules/userAffirmationProgram/destroy/userAffirmationProgramDestroyReducers';
import importerReducer from 'src/modules/userAffirmationProgram/importer/userAffirmationProgramImporterReducers';
import unarchive from 'src/modules/userAffirmationProgram/unarchive/userAffirmationProgramUnarchiveReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  unarchive,
  importer: importerReducer,
});
