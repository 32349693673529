import list from 'src/modules/userEntitlement/list/userEntitlementListReducers';
import form from 'src/modules/userEntitlement/form/userEntitlementFormReducers';
import view from 'src/modules/userEntitlement/view/userEntitlementViewReducers';
import destroy from 'src/modules/userEntitlement/destroy/userEntitlementDestroyReducers';
import importerReducer from 'src/modules/userEntitlement/importer/userEntitlementImporterReducers';
import unarchive from 'src/modules/userEntitlement/unarchive/userEntitlementUnarchiveReducers';
import deactivate from 'src/modules/userEntitlement/deactivate/userEntitlementDeactivateReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  unarchive,
  deactivate,
  importer: importerReducer,
});
