import { sortBy } from 'lodash';

const alexaI18nEnumerators = {
  code: sortBy(
    [
      'DEVICE_NOT_SUPPORTED',
      'ABOUT_FREQUENCIES_MSG',
      'LINK_ACCOUNT_MSG',
      'TEST_PARAMS',
      'NOT_IN_CURRENT_TIER_MSG',
      'CAN_NOT_SKIP_MSG',
      'UNHANDLED_MSG',
      'WELCOME_FIRST_MSG',
      'WELCOME_BACK_LINK_ACCOUNT_MSG',
      'TEST',
      'WELCOME_MSG',
      'RESUME_MSG',
      'NOT_POSSIBLE_MSG',
      'PLAYING_MSG',
      'WELCOME_BACK_MSG',
      'STOP_MSG',
      'WELCOME_POST_LINKED_MSG',
      'HELP_MSG',
      'ABOUT_MINDFUL_INTENTIONS_MSG',
      'ABOUT_MSG',
      'WELCOME_ENTERPRISE',
      'FREQUENCY_NOT_IN_SKILL_ENTERPRISE',
      'ABOUT_FREQUENCIES_MSG_ENTERPRISE',
      'ABOUT_MSG_ENTERPRISE',
      'CAN_NOT_SKIP_MSG_ENTERPRISE',
      'UNHANDLED_MSG_ENTERPRISE',
      'NOT_POSSIBLE_MSG_ENTERPRISE',
      'DEVICE_NOT_SUPPORTED_ENTERPRISE',
      'PLAYING_MSG_ENTERPRISE',
      'HELP_MSG_ENTERPRISE',
      'ABOUT_FREQUENCY_GENERIC_MSG',
      'ABOUT_FREQUENCY_GENERIC_MSG_ENTERPRISE',
      'TODAY_MINDFUL_INTENTIONS_MSG',
      'NO_ACTIVE_AFFIRMATION_PROGRAM_MSG',
      'TODAY_PROGRAM_INTENTION_MSG',
      'PROGRAM_COMMENTARY_QUESTION_MSG',
      'PROGRAM_SOUND_SINGLE_QUESTION_MSG',
      'PROGRAM_SOUND_DOUBLE_QUESTION_MSG',
      'DEFAULT',
      'NO_ACCESS_AFFIRMATION_PROGRAM_MSG',
      'NO_SOUND_ON_AFFIRMATION_PROGRAM_MSG',
      'AFFIRMATION_TYPE_QUESTION_MSG',
      'TODAY_PROGRAM_INTENTION_DAY_ZERO_MSG',
      'TODAY_PROGRAM_INTENTION_DAY_ZERO_WITH_SOUNDS_MSG',
      'MY_CHOICE_INTENT'
    ],
    (item) => item,
  ),
};

export default alexaI18nEnumerators;
