import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/subscriptionTier/importer/subscriptionTierImporterSelectors';
import SubscriptionTierService from 'src/modules/subscriptionTier/subscriptionTierService';
import fields from 'src/modules/subscriptionTier/importer/subscriptionTierImporterFields';
import { i18n } from 'src/i18n';

const subscriptionTierImporterActions = importerActions(
  'SUBSCRIPTIONTIER_IMPORTER',
  selectors,
  SubscriptionTierService.import,
  fields,
  i18n('entities.subscriptionTier.importer.fileName'),
);

export default subscriptionTierImporterActions;