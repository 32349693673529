import list from 'src/modules/subscriptionTier/list/subscriptionTierListReducers';
import form from 'src/modules/subscriptionTier/form/subscriptionTierFormReducers';
import view from 'src/modules/subscriptionTier/view/subscriptionTierViewReducers';
import destroy from 'src/modules/subscriptionTier/destroy/subscriptionTierDestroyReducers';
import importerReducer from 'src/modules/subscriptionTier/importer/subscriptionTierImporterReducers';
import unarchive from 'src/modules/subscriptionTier/unarchive/subscriptionTierUnarchiveReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  unarchive,
  importer: importerReducer,
});
