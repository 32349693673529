import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/affirmationProgram/importer/affirmationProgramImporterSelectors';
import AffirmationProgramService from 'src/modules/affirmationProgram/affirmationProgramService';
import fields from 'src/modules/affirmationProgram/importer/affirmationProgramImporterFields';
import { i18n } from 'src/i18n';

const affirmationProgramImporterActions = importerActions(
  'AFFIRMATIONPROGRAM_IMPORTER',
  selectors,
  AffirmationProgramService.import,
  fields,
  i18n('entities.affirmationProgram.importer.fileName'),
);

export default affirmationProgramImporterActions;