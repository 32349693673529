import { i18n } from 'src/i18n';
import { AuthCurrencies } from 'src/modules/auth/authCurrencies';
import entitlementEnumerators from 'src/modules/entitlement/entitlementEnumerators';
import schemas from 'src/modules/shared/yup/yupImporterSchemas';

export default [
  {
    name: 'title',
    label: i18n('entities.entitlement.fields.title'),
    schema: schemas.string(
      i18n('entities.entitlement.fields.title'),
      {},
    ),
  },
  {
    name: 'subscriptionTier',
    label: i18n(
      'entities.entitlement.fields.subscriptionTier',
    ),
    schema: schemas.relationToOne(
      i18n('entities.entitlement.fields.subscriptionTier'),
      {},
    ),
  },
  {
    name: 'source',
    label: i18n('entities.entitlement.fields.source'),
    schema: schemas.enumerator(
      i18n('entities.entitlement.fields.source'),
      {
        options: entitlementEnumerators.source,
      },
    ),
  },
  {
    name: 'currency',
    label: i18n('entities.entitlement.fields.currency'),
    schema: schemas.enumerator(
      i18n('entities.entitlement.fields.currency'),
      {
        options: AuthCurrencies.values,
      },
    ),
  },
  {
    name: 'storeID',
    label: i18n('entities.entitlement.fields.storeID'),
    schema: schemas.string(
      i18n('entities.entitlement.fields.storeID'),
      {},
    ),
  },
  {
    name: 'storeURL',
    label: i18n('entities.entitlement.fields.storeURL'),
    schema: schemas.string(
      i18n('entities.entitlement.fields.storeURL'),
      {},
    ),
  },
  {
    name: 'description',
    label: i18n('entities.entitlement.fields.description'),
    schema: schemas.string(
      i18n('entities.entitlement.fields.description'),
      {},
    ),
  },
  {
    name: 'renewalPeriod',
    label: i18n(
      'entities.entitlement.fields.renewalPeriod',
    ),
    schema: schemas.enumerator(
      i18n('entities.entitlement.fields.renewalPeriod'),
      {
        options: entitlementEnumerators.renewalPeriod,
      },
    ),
  },
  {
    name: 'retailPriceLabel',
    label: i18n(
      'entities.entitlement.fields.retailPriceLabel',
    ),
    schema: schemas.decimal(
      i18n('entities.entitlement.fields.retailPriceLabel'),
      {},
    ),
  },
  {
    name: 'retailPrice',
    label: i18n('entities.entitlement.fields.retailPrice'),
    schema: schemas.decimal(
      i18n('entities.entitlement.fields.retailPrice'),
      {},
    ),
  },
  {
    name: 'freeTrialDays',
    label: i18n(
      'entities.entitlement.fields.freeTrialDays',
    ),
    schema: schemas.decimal(
      i18n('entities.entitlement.fields.freeTrialDays'),
      {},
    ),
  },
  {
    name: 'lastSyncDate',
    label: i18n('entities.entitlement.fields.lastSyncDate'),
    schema: schemas.datetime(
      i18n('entities.entitlement.fields.lastSyncDate'),
      {},
    ),
  },
  {
    name: 'syncJSON',
    label: i18n('entities.entitlement.fields.syncJSON'),
    schema: schemas.string(
      i18n('entities.entitlement.fields.syncJSON'),
      {},
    ),
  },
];
