import EnterpriseAlexaSkillService from 'src/modules/enterpriseAlexaSkill/enterpriseAlexaSkillService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'ENTERPRISEALEXASKILL_VIEW';

const enterpriseAlexaSkillViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: enterpriseAlexaSkillViewActions.FIND_STARTED,
      });

      const record = await EnterpriseAlexaSkillService.find(id);

      dispatch({
        type: enterpriseAlexaSkillViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: enterpriseAlexaSkillViewActions.FIND_ERROR,
      });

      getHistory().push('/enterprise-alexa-skill');
    }
  },
};

export default enterpriseAlexaSkillViewActions;
