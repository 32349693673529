import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import affirmationProgramEnumerators from 'src/modules/affirmationProgram/affirmationProgramEnumerators';

export default [
  {
    name: 'title',
    label: i18n('entities.affirmationProgram.fields.title'),
    schema: schemas.string(
      i18n('entities.affirmationProgram.fields.title'),
      {
        required: true,
        min: 1,
      },
    ),
  },
  {
    name: 'type',
    label: i18n('entities.affirmationProgram.fields.type'),
    schema: schemas.enumerator(
      i18n('entities.affirmationProgram.fields.type'),
      {
        required: true,
        options: affirmationProgramEnumerators.type,
      },
    ),
  },
  {
    name: 'orderInList',
    label: i18n(
      'entities.affirmationProgram.fields.orderInList',
    ),
    schema: schemas.integer(
      i18n(
        'entities.affirmationProgram.fields.orderInList',
      ),
      {},
    ),
  },
  {
    name: 'includedInTier',
    label: i18n(
      'entities.affirmationProgram.fields.includedInTier',
    ),
    schema: schemas.relationToMany(
      i18n(
        'entities.affirmationProgram.fields.includedInTier',
      ),
      {
        required: true,
        min: 1,
      },
    ),
  },
  {
    name: 'sendToAllUsersInTier',
    label: i18n(
      'entities.affirmationProgram.fields.sendToAllUsersInTier',
    ),
    schema: schemas.boolean(
      i18n(
        'entities.affirmationProgram.fields.sendToAllUsersInTier',
      ),
    ),
  },
  {
    name: 'slug',
    label: i18n('entities.affirmationProgram.fields.slug'),
    schema: schemas
      .string(
        i18n('entities.affirmationProgram.fields.slug'),
        {
          required: true,
        },
      )
      .matches(
        /^[a-z0-9][-a-zA-Z0-9]*$/,
        i18n('entities.affirmationProgram.validation.slug'),
      ),
  },
  {
    name: 'summary',
    label: i18n(
      'entities.affirmationProgram.fields.summary',
    ),
    schema: schemas.string(
      i18n('entities.affirmationProgram.fields.summary'),
      {
        max: 160,
      },
    ),
  },
  {
    name: 'description',
    label: i18n(
      'entities.affirmationProgram.fields.description',
    ),
    schema: schemas.string(
      i18n(
        'entities.affirmationProgram.fields.description',
      ),
      {},
    ),
  },
  {
    name: 'smsTemplate',
    label: i18n(
      'entities.affirmationProgram.fields.smsTemplate',
    ),
    schema: schemas.string(
      i18n(
        'entities.affirmationProgram.fields.smsTemplate',
      ),
    ),
  },
  {
    name: 'wrapUpSms',
    label: i18n(
      'entities.affirmationProgram.fields.wrapUpSms',
    ),
    schema: schemas.string(
      i18n('entities.affirmationProgram.fields.wrapUpSms'),
    ),
  },
  {
    name: 'welcomeSms',
    label: i18n(
      'entities.affirmationProgram.fields.welcomeSms',
    ),
    schema: schemas.string(
      i18n('entities.affirmationProgram.fields.welcomeSms'),
    ),
  },
  {
    name: 'notificationTemplate',
    label: i18n(
      'entities.affirmationProgram.fields.notificationTemplate',
    ),
    schema: schemas.string(
      i18n(
        'entities.affirmationProgram.fields.notificationTemplate',
      ),
    ),
  },
  {
    name: 'wrapUpNotification',
    label: i18n(
      'entities.affirmationProgram.fields.wrapUpNotification',
    ),
    schema: schemas.string(
      i18n(
        'entities.affirmationProgram.fields.wrapUpNotification',
      ),
    ),
  },
  {
    name: 'welcomeNotification',
    label: i18n(
      'entities.affirmationProgram.fields.welcomeNotification',
    ),
    schema: schemas.string(
      i18n(
        'entities.affirmationProgram.fields.welcomeNotification',
      ),
    ),
  },
  {
    name: 'hidden',
    label: i18n(
      'entities.affirmationProgram.fields.hidden',
    ),
    schema: schemas.boolean(
      i18n('entities.affirmationProgram.fields.hidden'),
      {},
    ),
  },
  {
    name: 'squareArt',
    label: i18n(
      'entities.affirmationProgram.fields.squareArt',
    ),
    schema: schemas.files(
      i18n('entities.affirmationProgram.fields.squareArt'),
      {
        max: 1,
      },
    ),
  },
  {
    name: 'squareArtHD',
    label: i18n(
      'entities.affirmationProgram.fields.squareArtHD',
    ),
    schema: schemas.files(
      i18n(
        'entities.affirmationProgram.fields.squareArtHD',
      ),
      {
        max: 1,
      },
    ),
  },
  {
    name: 'landscapeArt',
    label: i18n(
      'entities.affirmationProgram.fields.landscapeArt',
    ),
    schema: schemas.files(
      i18n(
        'entities.affirmationProgram.fields.landscapeArt',
      ),
      {
        max: 1,
      },
    ),
  },
  {
    name: 'landscapeArtHD',
    label: i18n(
      'entities.affirmationProgram.fields.landscapeArtHD',
    ),
    schema: schemas.files(
      i18n(
        'entities.affirmationProgram.fields.landscapeArtHD',
      ),
      {
        max: 1,
      },
    ),
  },
  {
    name: 'portraitArt',
    label: i18n(
      'entities.affirmationProgram.fields.portraitArt',
    ),
    schema: schemas.files(
      i18n(
        'entities.affirmationProgram.fields.portraitArt',
      ),
      {
        max: 1,
      },
    ),
  },
  {
    name: 'portraitArtHD',
    label: i18n(
      'entities.affirmationProgram.fields.portraitArtHD',
    ),
    schema: schemas.files(
      i18n(
        'entities.affirmationProgram.fields.portraitArtHD',
      ),
      {
        max: 1,
      },
    ),
  },
];
