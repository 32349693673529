import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/userEntitlement/importer/userEntitlementImporterSelectors';
import UserEntitlementService from 'src/modules/userEntitlement/userEntitlementService';
import fields from 'src/modules/userEntitlement/importer/userEntitlementImporterFields';
import { i18n } from 'src/i18n';

const userEntitlementImporterActions = importerActions(
  'USERENTITLEMENT_IMPORTER',
  selectors,
  UserEntitlementService.import,
  fields,
  i18n('entities.userEntitlement.importer.fileName'),
);

export default userEntitlementImporterActions;