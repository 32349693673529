import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/affirmationProgramEntitlement/importer/affirmationProgramEntitlementImporterSelectors';
import AffirmationProgramEntitlementService from 'src/modules/affirmationProgramEntitlement/affirmationProgramEntitlementService';
import fields from 'src/modules/affirmationProgramEntitlement/importer/affirmationProgramEntitlementImporterFields';
import { i18n } from 'src/i18n';

const affirmationProgramEntitlementImporterActions = importerActions(
  'ENTITLEMENT_IMPORTER',
  selectors,
  AffirmationProgramEntitlementService.import,
  fields,
  i18n(
    'entities.affirmationProgramEntitlement.importer.fileName',
  ),
);

export default affirmationProgramEntitlementImporterActions;
