import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.id',
    ),
  },
  {
    name: 'affirmationProgram',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.affirmationProgram',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'source',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.source',
    ),
  },
  {
    name: 'currency',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.currency',
    ),
  },
  {
    name: 'storeID',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.storeID',
    ),
  },

  {
    name: 'storeIDDiscount',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.storeIDDiscount',
    ),
  },
  {
    name: 'storeURL',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.storeURL',
    ),
  },
  {
    name: 'retailPrice',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.retailPrice',
    ),
    render: exporterRenders.decimal(),
  },
  {
    name: 'retailPriceDiscount',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.retailPriceDiscount',
    ),
    render: exporterRenders.decimal(),
  },

  {
    name: 'lastSyncDate',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.lastSyncDate',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'syncJSON',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.syncJSON',
    ),
  },
  {
    name: 'archived',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.archived',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'archivedBy',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.archivedBy',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'archivedAt',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.archivedAt',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'createdAt',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.createdAt',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.updatedAt',
    ),
    render: exporterRenders.datetime(),
  },
];
