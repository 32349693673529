import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.affirmationProgram.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.affirmationProgram.fields.title'),
  },
  {
    name: 'slug',
    label: i18n(
      'entities.affirmationProgramEntitlement.fields.slug',
    ),
  },
  {
    name: 'type',
    label: i18n('entities.affirmationProgram.fields.type'),
  },
  {
    name: 'orderInList',
    label: i18n(
      'entities.affirmationProgram.fields.orderInList',
    ),
  },
  {
    name: 'includedInTier',
    label: i18n(
      'entities.affirmationProgram.fields.includedInTier',
    ),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'sendToAllUsersInTier',
    label: i18n(
      'entities.affirmationProgram.fields.sendToAllUsersInTier',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'summary',
    label: i18n(
      'entities.affirmationProgram.fields.summary',
    ),
  },
  {
    name: 'description',
    label: i18n(
      'entities.affirmationProgram.fields.description',
    ),
  },
  {
    name: 'smsTemplate',
    label: i18n(
      'entities.affirmationProgram.fields.smsTemplate',
    ),
  },
  {
    name: 'notificationTemplate',
    label: i18n(
      'entities.affirmationProgram.fields.notificationTemplate',
    ),
  },
  {
    name: 'wrapUpSms',
    label: i18n(
      'entities.affirmationProgram.fields.wrapUpSms',
    ),
  },
  {
    name: 'wrapUpNotification',
    label: i18n(
      'entities.affirmationProgram.fields.wrapUpNotification',
    ),
  },
  {
    name: 'welcomeSms',
    label: i18n(
      'entities.affirmationProgram.fields.welcomeSms',
    ),
  },

  {
    name: 'welcomeNotification',
    label: i18n(
      'entities.affirmationProgram.fields.welcomeNotification',
    ),
  },
  {
    name: 'hidden',
    label: i18n(
      'entities.affirmationProgram.fields.hidden',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'squareArt',
    label: i18n(
      'entities.affirmationProgram.fields.squareArt',
    ),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'squareArtHD',
    label: i18n(
      'entities.affirmationProgram.fields.squareArtHD',
    ),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'landscapeArt',
    label: i18n(
      'entities.affirmationProgram.fields.landscapeArt',
    ),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'landscapeArtHD',
    label: i18n(
      'entities.affirmationProgram.fields.landscapeArtHD',
    ),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'portraitArt',
    label: i18n(
      'entities.affirmationProgram.fields.portraitArt',
    ),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'portraitArtHD',
    label: i18n(
      'entities.affirmationProgram.fields.portraitArtHD',
    ),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'archived',
    label: i18n(
      'entities.affirmationProgram.fields.archived',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'archivedBy',
    label: i18n(
      'entities.affirmationProgram.fields.archivedBy',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'archivedAt',
    label: i18n(
      'entities.affirmationProgram.fields.archivedAt',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'createdAt',
    label: i18n(
      'entities.affirmationProgram.fields.createdAt',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n(
      'entities.affirmationProgram.fields.updatedAt',
    ),
    render: exporterRenders.datetime(),
  },
];
