import listActions from 'src/modules/userEntitlement/list/userEntitlementListActions';
import UserEntitlementService from 'src/modules/userEntitlement/userEntitlementService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'USER_ENTITLEMENT_UNARCHIVE';

const userEntitlementUnarchiveActions = {
  UNARCHIVE_STARTED: `${prefix}_UNARCHIVE_STARTED`,
  UNARCHIVE_SUCCESS: `${prefix}_UNARCHIVE_SUCCESS`,
  UNARCHIVE_ERROR: `${prefix}_UNARCHIVE_ERROR`,

  UNARCHIVE_ALL_STARTED: `${prefix}_UNARCHIVE_ALL_STARTED`,
  UNARCHIVE_ALL_SUCCESS: `${prefix}_UNARCHIVE_ALL_SUCCESS`,
  UNARCHIVE_ALL_ERROR: `${prefix}_UNARCHIVE_ALL_ERROR`,

  doUnarchive: (id) => async (dispatch) => {
    try {
      dispatch({
        type:
          userEntitlementUnarchiveActions.UNARCHIVE_STARTED,
      });

      await UserEntitlementService.unarchiveAll([id]);

      dispatch({
        type:
          userEntitlementUnarchiveActions.UNARCHIVE_SUCCESS,
      });

      Message.success(
        i18n('entities.userEntitlement.unarchive.success'),
      );

      dispatch(listActions.doFetchCurrentFilter());

      // getHistory().push('/user-entitlement');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type:
          userEntitlementUnarchiveActions.UNARCHIVE_ERROR,
      });
    }
  },

  doUnarchiveAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type:
          userEntitlementUnarchiveActions.UNARCHIVE_ALL_STARTED,
      });

      await UserEntitlementService.unarchiveAll(ids);

      dispatch({
        type:
          userEntitlementUnarchiveActions.UNARCHIVE_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doClearAllSelected());
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(
        i18n(
          'entities.userEntitlement.unarchiveAll.success',
        ),
      );

      getHistory().push('/user-entitlement');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type:
          userEntitlementUnarchiveActions.UNARCHIVE_ALL_ERROR,
      });
    }
  },
};

export default userEntitlementUnarchiveActions;
