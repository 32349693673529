import list from 'src/modules/entitlement/list/entitlementListReducers';
import form from 'src/modules/entitlement/form/entitlementFormReducers';
import view from 'src/modules/entitlement/view/entitlementViewReducers';
import destroy from 'src/modules/entitlement/destroy/entitlementDestroyReducers';
import importerReducer from 'src/modules/entitlement/importer/entitlementImporterReducers';
import unarchive from 'src/modules/entitlement/unarchive/entitlementUnarchiveReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  unarchive,
  importer: importerReducer,
});
