import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/enterpriseAlexaSkill/importer/enterpriseAlexaSkillImporterSelectors';
import EnterpriseAlexaSkillService from 'src/modules/enterpriseAlexaSkill/enterpriseAlexaSkillService';
import fields from 'src/modules/enterpriseAlexaSkill/importer/enterpriseAlexaSkillImporterFields';
import { i18n } from 'src/i18n';

const enterpriseAlexaSkillImporterActions = importerActions(
  'ENTERPRISEALEXASKILL_IMPORTER',
  selectors,
  EnterpriseAlexaSkillService.import,
  fields,
  i18n('entities.enterpriseAlexaSkill.importer.fileName'),
);

export default enterpriseAlexaSkillImporterActions;