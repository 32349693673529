import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import soundEnumerators from 'src/modules/sound/soundEnumerators';

export default [
  {
    name: 'name',
    label: i18n('entities.sound.fields.name'),
    schema: schemas.string(
      i18n('entities.sound.fields.name'),
      {
        required: true,
        min: 1,
        max: 160,
      },
    ),
  },
  {
    name: 'soundTitle',
    label: i18n('entities.sound.fields.soundTitle'),
    schema: schemas.relationToOne(
      i18n('entities.sound.fields.soundTitle'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'type',
    label: i18n('entities.sound.fields.type'),
    schema: schemas.enumerator(
      i18n('entities.sound.fields.type'),
      { required: true, options: soundEnumerators.type },
    ),
  },
  // {
  //   name: 'summary',
  //   label: i18n('entities.sound.fields.summary'),
  //   schema: schemas.string(
  //     i18n('entities.sound.fields.summary'),
  //     {
  //       max: 160,
  //     },
  //   ),
  // },
  // {
  //   name: 'summarySSML',
  //   label: i18n('entities.sound.fields.summarySSML'),
  //   schema: schemas.string(
  //     i18n('entities.sound.fields.summarySSML'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'description',
  //   label: i18n('entities.sound.fields.description'),
  //   schema: schemas.string(
  //     i18n('entities.sound.fields.description'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'descriptionSSML',
  //   label: i18n('entities.sound.fields.descriptionSSML'),
  //   schema: schemas.string(
  //     i18n('entities.sound.fields.descriptionSSML'),
  //     {},
  //   ),
  // },
  {
    name: 'hidden',
    label: i18n('entities.sound.fields.hidden'),
    schema: schemas.boolean(
      i18n('entities.sound.fields.hidden'),
      {},
    ),
  },
  {
    name: 'audioURL',
    label: i18n('entities.sound.fields.audioURL'),
    schema: schemas.files(
      i18n('entities.sound.fields.audioURL'),
      {
        required: true,
        max: 1,
      },
    ),
  },
  // {
  //   name: 'alexaAudioURL',
  //   label: i18n('entities.sound.fields.alexaAudioURL'),
  //   schema: schemas.files(
  //     i18n('entities.sound.fields.alexaAudioURL'),
  //     {
  //       max: 1,
  //     },
  //   ),
  // },
  // {
  //   name: 'squareArt',
  //   label: i18n('entities.sound.fields.squareArt'),
  //   schema: schemas.files(
  //     i18n('entities.sound.fields.squareArt'),
  //     {
  //       max: 1,
  //     },
  //   ),
  // },
  // {
  //   name: 'squareArtHD',
  //   label: i18n('entities.sound.fields.squareArtHD'),
  //   schema: schemas.files(
  //     i18n('entities.sound.fields.squareArtHD'),
  //     {
  //       max: 1,
  //     },
  //   ),
  // },
  // {
  //   name: 'landscapeArt',
  //   label: i18n('entities.sound.fields.landscapeArt'),
  //   schema: schemas.files(
  //     i18n('entities.sound.fields.landscapeArt'),
  //     {
  //       max: 1,
  //     },
  //   ),
  // },
  // {
  //   name: 'landscapeArtHD',
  //   label: i18n('entities.sound.fields.landscapeArtHD'),
  //   schema: schemas.files(
  //     i18n('entities.sound.fields.landscapeArtHD'),
  //     {
  //       max: 1,
  //     },
  //   ),
  // },
  // {
  //   name: 'portraitArt',
  //   label: i18n('entities.sound.fields.portraitArt'),
  //   schema: schemas.files(
  //     i18n('entities.sound.fields.portraitArt'),
  //     {
  //       max: 1,
  //     },
  //   ),
  // },
  // {
  //   name: 'portraitArtHD',
  //   label: i18n('entities.sound.fields.portraitArtHD'),
  //   schema: schemas.files(
  //     i18n('entities.sound.fields.portraitArtHD'),
  //     {
  //       max: 1,
  //     },
  //   ),
  // },
];
