import listActions from 'src/modules/affirmationProgramEntitlement/list/affirmationProgramEntitlementListActions';
import AffirmationProgramEntitlementService from 'src/modules/affirmationProgramEntitlement/affirmationProgramEntitlementService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'ENTITLEMENT_UNARCHIVE';

const affirmationProgramEntitlementUnarchiveActions = {
  UNARCHIVE_STARTED: `${prefix}_UNARCHIVE_STARTED`,
  UNARCHIVE_SUCCESS: `${prefix}_UNARCHIVE_SUCCESS`,
  UNARCHIVE_ERROR: `${prefix}_UNARCHIVE_ERROR`,

  UNARCHIVE_ALL_STARTED: `${prefix}_UNARCHIVE_ALL_STARTED`,
  UNARCHIVE_ALL_SUCCESS: `${prefix}_UNARCHIVE_ALL_SUCCESS`,
  UNARCHIVE_ALL_ERROR: `${prefix}_UNARCHIVE_ALL_ERROR`,

  doUnarchive: (id) => async (dispatch) => {
    try {
      dispatch({
        type:
          affirmationProgramEntitlementUnarchiveActions.UNARCHIVE_STARTED,
      });

      await AffirmationProgramEntitlementService.unarchiveAll(
        [id],
      );

      dispatch({
        type:
          affirmationProgramEntitlementUnarchiveActions.UNARCHIVE_SUCCESS,
      });

      Message.success(
        i18n(
          'entities.affirmationProgramEntitlement.unarchive.success',
        ),
      );

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/affirmation-program-entitlement');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type:
          affirmationProgramEntitlementUnarchiveActions.UNARCHIVE_ERROR,
      });
    }
  },

  doUnarchiveAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type:
          affirmationProgramEntitlementUnarchiveActions.UNARCHIVE_ALL_STARTED,
      });

      await AffirmationProgramEntitlementService.unarchiveAll(
        ids,
      );

      dispatch({
        type:
          affirmationProgramEntitlementUnarchiveActions.UNARCHIVE_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doClearAllSelected());
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(
        i18n(
          'entities.affirmationProgramEntitlement.unarchiveAll.success',
        ),
      );

      getHistory().push('/affirmation-program-entitlement');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type:
          affirmationProgramEntitlementUnarchiveActions.UNARCHIVE_ALL_ERROR,
      });
    }
  },
};

export default affirmationProgramEntitlementUnarchiveActions;
