import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.enterpriseAlexaSkill.fields.id'),
  },
  {
    name: 'partner',
    label: i18n('entities.enterpriseAlexaSkill.fields.partner'),
  },
  {
    name: 'installation',
    label: i18n('entities.enterpriseAlexaSkill.fields.installation'),
  },
  {
    name: 'skillId',
    label: i18n('entities.enterpriseAlexaSkill.fields.skillId'),
  },
  {
    name: 'soundTitles',
    label: i18n('entities.enterpriseAlexaSkill.fields.soundTitles'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'live',
    label: i18n('entities.enterpriseAlexaSkill.fields.live'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.enterpriseAlexaSkill.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.enterpriseAlexaSkill.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
