import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.entitlement.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.entitlement.fields.title'),
  },
  {
    name: 'subscriptionTier',
    label: i18n(
      'entities.entitlement.fields.subscriptionTier',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'source',
    label: i18n('entities.entitlement.fields.source'),
  },
  {
    name: 'currency',
    label: i18n('entities.entitlement.fields.currency'),
  },
  {
    name: 'storeID',
    label: i18n('entities.entitlement.fields.storeID'),
  },
  {
    name: 'storeURL',
    label: i18n('entities.entitlement.fields.storeURL'),
  },
  {
    name: 'description',
    label: i18n('entities.entitlement.fields.description'),
  },
  {
    name: 'renewalPeriod',
    label: i18n(
      'entities.entitlement.fields.renewalPeriod',
    ),
  },
  {
    name: 'retailPriceLabel',
    label: i18n(
      'entities.entitlement.fields.retailPriceLabel',
    ),
  },
  {
    name: 'retailPrice',
    label: i18n('entities.entitlement.fields.retailPrice'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'freeTrialDays',
    label: i18n(
      'entities.entitlement.fields.freeTrialDays',
    ),
  },

  {
    name: 'lastSyncDate',
    label: i18n('entities.entitlement.fields.lastSyncDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'syncJSON',
    label: i18n('entities.entitlement.fields.syncJSON'),
  },
  {
    name: 'archived',
    label: i18n('entities.entitlement.fields.archived'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'archivedBy',
    label: i18n('entities.entitlement.fields.archivedBy'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'archivedAt',
    label: i18n('entities.entitlement.fields.archivedAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.entitlement.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.entitlement.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
