import authAxios from 'src/modules/shared/axios/authAxios';

export default class ReportingService {
  static async fetch(filter) {
    const params = {
      filter,
    };

    const response = await authAxios.get(`/reporting`, {
      params,
    });

    return response.data;
  }
}
