import { faAmazon } from '@fortawesome/free-brands-svg-icons';
import {
  faCreditCard,
  faEnvelope,
  faFileAudio,
} from '@fortawesome/free-regular-svg-icons';
import {
  faBuilding,
  faFileContract,
  faHistory,
  faHome,
  faIdCard,
  faInbox,
  faLeaf,
  faMailBulk,
  faPlayCircle,
  faStar,
  faTachometerAlt,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import { i18n } from 'src/i18n';
import Permissions from 'src/security/permissions';

const permissions = Permissions.values;

export default [
  {
    path: '/',
    label: i18n('home.menu'),
    permissionRequired: null,
    icon: faHome,
    imageIcon: '/images/home-icon.svg',
  },

  {
    path: '/user',
    label: i18n('user.menu'),
    permissionRequired: permissions.userRead,
    icon: faUserPlus,
    imageIcon: '/images/user-icon.svg',
  },

  {
    path: '/audit-logs',
        imageIcon: '/images/audit-icon.svg',
    icon: faHistory,
    label: i18n('auditLog.menu'),
    permissionRequired: permissions.auditLogRead,
  },
  {
    path: '/push-notifications',
        imageIcon: '/images/audit-icon.svg',
    icon: faHistory,
    label: i18n('Notifications'),
    permissionRequired: permissions.affirmationRead,
  },

  {
    path: '/affirmation',
    permissionRequired: permissions.affirmationRead,
        imageIcon: '/images/mail-icon.svg',
    icon: faEnvelope,
    label: i18n('entities.affirmation.menu'),
  },

  {
    path: '/affirmation-program',
    permissionRequired: permissions.affirmationProgramRead,
        imageIcon: '/images/bulk-icon.svg',
    icon: faMailBulk,
    label: i18n('entities.affirmationProgram.menu'),
  },
  // {
  //   path: '/21-day-programs',
  //   permissionRequired: permissions.affirmationProgramRead,
  //       imageIcon: '/images/bulk-icon.svg',
  //   icon: faMailBulk,
  //   label: "21 Day Program",
  // },

  {
    path: '/wellness-check',
    permissionRequired: permissions.wellnessCheckRead,
        imageIcon: '/images/wellness-icon.svg',
    icon: faLeaf,
    label: i18n('entities.wellnessCheck.menu'),
  },

  {
    path: '/sound',
    permissionRequired: permissions.soundRead,
        imageIcon: '/images/sound-icon.svg',
    icon: faPlayCircle,
    label: i18n('entities.sound.menu'),
  },

  {
    path: '/sound-title',
    permissionRequired: permissions.soundTitleRead,
        imageIcon: '/images/mute-icon.svg',
    icon: faFileAudio,
    label: i18n('entities.soundTitle.menu'),
  },

  {
    path: '/subscription-tier',
    permissionRequired: permissions.subscriptionTierRead,
        imageIcon: '/images/subscription-card-icon.svg',
    icon: faCreditCard,
    label: i18n('entities.subscriptionTier.menu'),
  },

  {
    path: '/reporting',
    permissionRequired: permissions.reporting,
        imageIcon: '/images/star-icon.svg',
    icon: faStar,
    label: i18n('entities.reporting.menu'),
  },

  {
    path: '/entitlement',
    permissionRequired: permissions.entitlementRead,
        imageIcon: '/images/affirmation-entitlement.svg',
    icon: faFileContract,
    label: i18n('entities.entitlement.menu'),
  },

  {
    path: '/user-entitlement',
    permissionRequired: permissions.userEntitlementRead,
        imageIcon: '/images/user-entitlements.svg',
    icon: faIdCard,
    label: i18n('entities.userEntitlement.menu'),
  },
  // {
  //   path: '/cancelled-entitlement',
  //   permissionRequired:
  //     permissions.userAffirmationProgramRead,
  //       imageIcon: '/images/cancel_subscription.svg',
  //     icon: faInbox,
  //   label: i18n("Cancelled Entitlements"),
  // },
  {
    path: '/cancelled-subscription',
    permissionRequired:
      permissions.userAffirmationProgramRead,
        imageIcon: '/images/new_user-entitlements 1.svg',
      icon: faInbox,
    label: i18n("Cancelled Entitlements"),
  },
  {
    path: '/affirmation-program-entitlement',
    permissionRequired:
      permissions.affirmationProgramEntitlementRead,
        imageIcon: '/images/affirmation-entitlement.svg',
      icon: faFileContract,
    label: i18n(
      'entities.affirmationProgramEntitlement.menu',
    ),
  },

  {
    path: '/user-affirmation-program',
    permissionRequired:
      permissions.userAffirmationProgramRead,
        imageIcon: '/images/affirmation-icon.svg',
      icon: faInbox,
    label: i18n('entities.userAffirmationProgram.menu'),
  },
  {
    path: '/add-programs',
    permissionRequired:
      permissions.userAffirmationProgramRead,
        imageIcon: '/images/affirmation-icon.svg',
      icon: faInbox,
    label: "Add Courses",
  },
  {
    path: '/courses',
    permissionRequired:
      permissions.userAffirmationProgramRead,
        imageIcon: '/images/affirmation-icon.svg',
      icon: faInbox,
    label: "Courses",
  },


  {
    path: '/enterprise-alexa-skill',
    permissionRequired:
      permissions.enterpriseAlexaSkillRead,
        imageIcon: '/images/building-icon.svg',
      icon: faBuilding,
    label: i18n('entities.enterpriseAlexaSkill.menu'),
  },

  {
    path: '/alexa-i18n',
    permissionRequired: permissions.alexaI18nRead,
        imageIcon: '/images/alexa.svg',
    icon: faAmazon,
    label: i18n('entities.alexaI18n.menu'),
  },

  {
    path: '/usage-log',
    permissionRequired: permissions.usageLogRead,
        imageIcon: '/images/usage-icon.svg',
    icon: faTachometerAlt,
    label: i18n('entities.usageLog.menu'),
  },
].filter(Boolean);
