import list from 'src/modules/affirmation/list/affirmationListReducers';
import form from 'src/modules/affirmation/form/affirmationFormReducers';
import view from 'src/modules/affirmation/view/affirmationViewReducers';
import destroy from 'src/modules/affirmation/destroy/affirmationDestroyReducers';
import unarchive from 'src/modules/affirmation/unarchive/affirmationUnarchiveReducers';
import importerReducer from 'src/modules/affirmation/importer/affirmationImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  unarchive,
  importer: importerReducer,
});
