import listActions from 'src/modules/affirmation/list/affirmationListActions';
import AffirmationService from 'src/modules/affirmation/affirmationService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'AFFIRMATION_UNARCHIVE';

const affirmationUnarchiveActions = {
  UNARCHIVE_STARTED: `${prefix}_UNARCHIVE_STARTED`,
  UNARCHIVE_SUCCESS: `${prefix}_UNARCHIVE_SUCCESS`,
  UNARCHIVE_ERROR: `${prefix}_UNARCHIVE_ERROR`,

  UNARCHIVE_ALL_STARTED: `${prefix}_UNARCHIVE_ALL_STARTED`,
  UNARCHIVE_ALL_SUCCESS: `${prefix}_UNARCHIVE_ALL_SUCCESS`,
  UNARCHIVE_ALL_ERROR: `${prefix}_UNARCHIVE_ALL_ERROR`,

  doUnarchive: (id) => async (dispatch) => {
    try {
      dispatch({
        type: affirmationUnarchiveActions.UNARCHIVE_STARTED,
      });

      await AffirmationService.unarchiveAll([id]);

      dispatch({
        type: affirmationUnarchiveActions.UNARCHIVE_SUCCESS,
      });

      Message.success(
        i18n('entities.affirmation.unarchive.success'),
      );

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/affirmation');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: affirmationUnarchiveActions.UNARCHIVE_ERROR,
      });
    }
  },

  doUnarchiveAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type:
          affirmationUnarchiveActions.UNARCHIVE_ALL_STARTED,
      });

      await AffirmationService.unarchiveAll(ids);

      dispatch({
        type:
          affirmationUnarchiveActions.UNARCHIVE_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doClearAllSelected());
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(
        i18n('entities.affirmation.unarchiveAll.success'),
      );

      getHistory().push('/affirmation');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type:
          affirmationUnarchiveActions.UNARCHIVE_ALL_ERROR,
      });
    }
  },
};

export default affirmationUnarchiveActions;
