import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'title',
    label: i18n('entities.soundTitle.fields.title'),
    schema: schemas.string(
      i18n('entities.soundTitle.fields.title'),
      {
        required: true,
        min: 2,
      },
    ),
  },
  {
    name: 'slug',
    label: i18n('entities.soundTitle.fields.slug'),
    schema: schemas
      .string(i18n('entities.soundTitle.fields.slug'), {
        required: true,
      })
      .matches(
        /^[a-z0-9][-a-zA-Z0-9]*$/,
        i18n('entities.soundTitle.validation.slug'),
      ),
  },
  // {
  //   name: 'summarySSML',
  //   label: i18n('entities.soundTitle.fields.summarySSML'),
  //   schema: schemas.string(
  //     i18n('entities.soundTitle.fields.summarySSML'),
  //     {},
  //   ),
  // },
  {
    name: 'description',
    label: i18n('entities.soundTitle.fields.description'),
    schema: schemas.string(
      i18n('entities.soundTitle.fields.description'),
      {},
    ),
  },
  {
    name: 'descriptionSSML',
    label: i18n(
      'entities.soundTitle.fields.descriptionSSML',
    ),
    schema: schemas.string(
      i18n('entities.soundTitle.fields.descriptionSSML'),
      {},
    ),
  },
  {
    name: 'orderInList',
    label: i18n('entities.soundTitle.fields.orderInList'),
    schema: schemas.integer(
      i18n('entities.soundTitle.fields.orderInList'),
      {},
    ),
  },
  {
    name: 'wellnessCheck',
    label: i18n('entities.soundTitle.fields.wellnessCheck'),
    schema: schemas.relationToOne(
      i18n('entities.soundTitle.fields.wellnessCheck'),
      {},
    ),
  },
  {
    name: 'includedInTier',
    label: i18n(
      'entities.soundTitle.fields.includedInTier',
    ),
    schema: schemas.relationToMany(
      i18n('entities.soundTitle.fields.includedInTier'),
    ),
  },
  {
    name: 'hidden',
    label: i18n('entities.soundTitle.fields.hidden'),
    schema: schemas.boolean(
      i18n('entities.soundTitle.fields.hidden'),
      {},
    ),
  },
  {
    name: 'squareArt',
    label: i18n('entities.soundTitle.fields.squareArt'),
    schema: schemas.files(
      i18n('entities.soundTitle.fields.squareArt'),
      {
        max: 1,
      },
    ),
  },
  {
    name: 'squareArtHD',
    label: i18n('entities.soundTitle.fields.squareArtHD'),
    schema: schemas.files(
      i18n('entities.soundTitle.fields.squareArtHD'),
      {
        max: 1,
      },
    ),
  },
  {
    name: 'landscapeArt',
    label: i18n('entities.soundTitle.fields.landscapeArt'),
    schema: schemas.files(
      i18n('entities.soundTitle.fields.landscapeArt'),
      {
        max: 1,
      },
    ),
  },
  {
    name: 'landscapeArtHD',
    label: i18n(
      'entities.soundTitle.fields.landscapeArtHD',
    ),
    schema: schemas.files(
      i18n('entities.soundTitle.fields.landscapeArtHD'),
      {
        max: 1,
      },
    ),
  },
  {
    name: 'portraitArt',
    label: i18n('entities.soundTitle.fields.portraitArt'),
    schema: schemas.files(
      i18n('entities.soundTitle.fields.portraitArt'),
      {
        max: 1,
      },
    ),
  },
  {
    name: 'portraitArtHD',
    label: i18n('entities.soundTitle.fields.portraitArtHD'),
    schema: schemas.files(
      i18n('entities.soundTitle.fields.portraitArtHD'),
      {
        max: 1,
      },
    ),
  },
];
