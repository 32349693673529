import list from 'src/modules/affirmationProgram/list/affirmationProgramListReducers';
import form from 'src/modules/affirmationProgram/form/affirmationProgramFormReducers';
import view from 'src/modules/affirmationProgram/view/affirmationProgramViewReducers';
import destroy from 'src/modules/affirmationProgram/destroy/affirmationProgramDestroyReducers';
import importerReducer from 'src/modules/affirmationProgram/importer/affirmationProgramImporterReducers';
import unarchive from 'src/modules/affirmationProgram/unarchive/affirmationProgramUnarchiveReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  unarchive,
  importer: importerReducer,
});
