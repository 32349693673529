import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import alexaI18nEnumerators from 'src/modules/alexaI18n/alexaI18nEnumerators';

export default [
  {
    name: 'code',
    label: i18n('entities.alexaI18n.fields.code'),
    schema: schemas.enumerator(
      i18n('entities.alexaI18n.fields.code'),
      {
        required: true,
        options: alexaI18nEnumerators.code,
      },
    ),
  },
  {
    name: 'text',
    label: i18n('entities.alexaI18n.fields.text'),
    schema: schemas.string(
      i18n('entities.alexaI18n.fields.text'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'locale',
    label: i18n('entities.alexaI18n.fields.locale'),
    schema: schemas.string(
      i18n('entities.alexaI18n.fields.locale'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'version',
    label: i18n('entities.alexaI18n.fields.version'),
    schema: schemas.string(
      i18n('entities.alexaI18n.fields.version'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'enterpriseAlexaSkills',
    label: i18n(
      'entities.alexaI18n.fields.enterpriseAlexaSkills',
    ),
    schema: schemas.relationToMany(
      i18n(
        'entities.alexaI18n.fields.enterpriseAlexaSkills',
      ),
      {},
    ),
  },
];
