import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/soundTitle/importer/soundTitleImporterSelectors';
import SoundTitleService from 'src/modules/soundTitle/SoundTitleService';
import fields from 'src/modules/soundTitle/importer/soundTitleImporterFields';
import { i18n } from 'src/i18n';

const soundTitleImporterActions = importerActions(
  'SOUNDTITLE_IMPORTER',
  selectors,
  SoundTitleService.import,
  fields,
  i18n('entities.soundTitle.importer.fileName'),
);

export default soundTitleImporterActions;